import React from 'react'
import color from 'color'
import clsx from 'clsx'
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles(theme => ({
  baseTable: {
    whiteSpace: "nowrap",
    '& th': {
      textAlign: "center",
    },

    // base helpers

    // minimize column to content width (ish)
    '& td.shrinkCell, & th.shrinkCell': {
      width: "1%",
    },

    '& .right': {
      textAlign: 'right',
    },

    '& .left': {
      textAlign: 'left',
    },

    '& .center': {
      textAlign: 'center',
    },

    '& .wrap': {
      whiteSpace: "normal",
    },

    '& td.danger': {
      color: theme.colors.danger.color,
    },

    '& td.success': {
      color: theme.colors.success.color,
    },

    '& td.warning': {
      color: theme.colors.warning.color,
    },

    '& tr.active td': {
      backgroundColor: theme.colors.primary.color,
      color: theme.colors.primary.offset,
    },

    // set pointer cursor on clickable rows.
    '& tr.clickable, & td.clickable, & th.clickable': {
      cursor: 'pointer'
    }
  },

  // TABLE VARIANTS
  dataTable: {
    flexGrow: "1",

    // ensures the head doesn't move when sticky
    borderCollapse: "collapse",

    // base cell styles
    '& th, & td': {
      verticalAlign: "top",
      paddingTop: theme.space.xs,
      paddingBottom: theme.space.xs,
      paddingLeft: theme.space.sm,
      paddingRight: theme.space.sm,
    },
    '& tbody tr': {
      borderBottom: "1px solid " + color(theme.colors.primary.color).alpha(.1).rgb().string()
    },
    '& thead th': {
      backgroundColor: theme.colors.primary.color,
      color: theme.colors.primary.offset,
    }
  },

  fieldTable: { // identical to dataTable, but without padding: better way?
    flexGrow: "1",

    // ensures the head doesn't move when sticky
    borderCollapse: "collapse",

    '& th': {
      verticalAlign: "top",
      paddingTop: theme.space.xs,
      paddingBottom: theme.space.xs,
      paddingLeft: theme.space.sm,
      paddingRight: theme.space.sm,
    },

    // base cell styles
    '& td': {
      verticalAlign: "top",
      paddingTop: "0px",
      paddingBottom: "0px",
      paddingLeft: "0px",
      paddingRight: "0px",
    },
    '& tbody tr': {
      borderBottom: "1px solid " + color(theme.colors.primary.color).alpha(.1).rgb().string()
    },
    '& thead th': {
      backgroundColor: theme.colors.primary.color,
      color: theme.colors.primary.offset,
    }
  },

  lineItem: {
    //tableLayout: "fixed",
    width: "100%",
    borderCollapse: "collapse",
    '& th': {
      textAlign: "center",
      borderBottom: "1px solid",
      borderColor: theme.colors.default.color,
    },
    '& td': {
      borderLeft: "1px solid",
      borderRight: "1px solid",
      borderColor: theme.colors.default.color,
      paddingLeft: theme.space.sm,
      paddingRight: theme.space.sm,
      paddingBottom: theme.space.xs,
    },
    '& tr:last-child td': {
      borderBottom: "1px solid",
      borderColor: theme.colors.default.color,
    },
    '& tbody tr:hover': {
      backgroundColor: color(theme.colors.primary.color).alpha(.1).rgb().string(),
    } ,
  },

  specTable: {
    '& th': {
      textAlign: "right",
      width: "10%",
      paddingRight: theme.space.sm,
    },
    '& th:only-child': {
      textAlign: "center",
      paddingRight: '0',
    },
  },

  // HELPERS
  stickyHeadings: {
    '& thead th': {
      backgroundColor: theme.colors.default.backgroundColor,
      position: "sticky",
      marginLeft: "0px",
      marginRight: "0px",
      marginBottom: "0px",
      marginTop: "0px",
      top: "0px",
    },
  },
  fullWidth: {
    width: "100%",
  },
  highlightHover: {
    '& tbody tr:hover': {
      backgroundColor: color(theme.colors.primary.color).alpha(.1).rgb().string(),
    },
  },

}))

const Table = (props) => {

  const {
    children,
    fullWidth,
    tableStyle = 'default',
    highlightHover = false,
  } = props

  const classes = useStyles()

  return (

    <table
      className={clsx(
        classes.baseTable,
        classes[tableStyle] && classes[tableStyle],
        fullWidth && classes.fullWidth,
        highlightHover && classes.highlightHover,
        //tableStyle === 'default' && classes.default,
        //tableStyle === 'dataTable' && classes.dataTable,
      )}
    >
      {children}
    </table>

  )

}

export default Table
