import React from 'react'
import color from 'color'
import clsx from 'clsx'
import { debounce } from 'lodash'
import { createUseStyles } from 'react-jss'

import { useBlockContext } from 'hoodoo/hooks'


const sizes = ['xxs', 'xs', 'sm', 'md', 'lg', 'xl', 'xxl'] 
const colors = ['default', 'primary', 'secondary', 'success', 'warning', 'danger']

const useStyles = createUseStyles(theme => ({
  button: {
    ...(sizes.reduce((acc, cur) => {
      acc[`&.${cur}`] = {

        fontSize: theme.size[cur],

      }
      return acc
    }, {})),

    '&.bold': {
      fontWeight: 'bold',
    },

    '&.disabled': {
      cursor: 'not-allowed',
    },
    '&.fullWidth': {
      width: '100%',
    },

  },

  asLink: {
    marginLeft: theme.space.xxs,
    marginRight: theme.space.xxs,

    paddingLeft: theme.space.xxs,
    paddingRight: theme.space.xxs,
    //paddingLeft: "0px",
    //paddingRight: "0px",
    paddingTop: theme.space.xxs,
    paddingBottom: theme.space.xxs,

    background: "none",
    border: "none",
    borderRadius: theme.space.md,

    cursor: 'pointer',

    ...(colors.reduce((acc, cur) => {
      acc[`&.${cur}`] = {
        color: theme.colors[cur].color,

        '&:hover, &:focus': {
        },

        '&:active': {
          color: color(theme.colors[cur].color).darken(.1).hex(),
        },

        '& > .ripple': {
          background: theme.colors[cur].color,
        },

        '&.disabled': {
          color: color(theme.colors[cur].color).alpha(.5).rgb().string(),
        },
      }
      return acc
    },{})),
  },

  asTextLink: {
    //marginLeft: theme.space.xxs,
    //marginRight: theme.space.xxs,

    //paddingLeft: theme.space.xxs,
    //paddingRight: theme.space.xxs,
    //paddingLeft: "0px",
    //paddingRight: "0px",
    //paddingTop: theme.space.xxs,
    //paddingBottom: theme.space.xxs,
    padding: "0",
    background: "none",
    border: "none",
    textAlign: "left",
    //borderRadius: theme.space.md,

    cursor: 'pointer',

    ...(colors.reduce((acc, cur) => {
      acc[`&.${cur}`] = {
        color: theme.colors[cur].color,

        '&:hover, &:focus': {
        },

        '&:active': {
          color: color(theme.colors[cur].color).darken(.1).hex(),
        },

        '& > .ripple': {
          background: theme.colors[cur].color,
        },

        '&.disabled': {
          color: color(theme.colors[cur].color).alpha(.5).rgb().string(),
        },
      }
      return acc
    },{})),

  },

  asButton: {
    paddingLeft: theme.space.md,
    paddingRight: theme.space.md,
    paddingTop: theme.space.xxs,
    paddingBottom: theme.space.xxs,

    borderColor: "transparent",
    borderWidth: "1px",
    borderRadius: theme.space.xxs,
    borderStyle: "solid",

    boxSizing: "border-box",

    cursor: 'pointer',

    ...(colors.reduce((acc, cur) => {
      acc[`&.${cur}`] = {
        color: theme.colors[cur].offset,
        //borderColor: theme.colors[cur].color,
        backgroundColor: theme.colors[cur].color,

        '&:hover, &:focus': {
          boxShadow: `3px 3px 6px -1px ${color(theme.colors[cur].color).darken(.5).alpha(.9).hsl()}`,
          borderColor: color(theme.colors[cur].color).darken(.1).hex() + " !important",
        },

        '&:active': {
          boxShadow: `0px 0px 6px 0px ${color(theme.colors[cur].color).darken(.5).alpha(.9).hsl()}`,
          borderColor: 'transparent',
        },

        '& > .ripple': {
          background: theme.colors[cur].offset,
        },

        '&.disabled': {
          backgroundColor: color(theme.colors[cur].color).alpha(.5).rgb().string(),
          color: color(theme.colors[cur].offset).alpha(.5).rgb().string(),
          boxShadow: 'none',
          borderColor: 'transparent',
        },
      }
      return acc
    },{})),

  },

  rippleButton: {
    overflow: 'hidden',
    position: 'relative',
    '& > .ripple': {
      width: '20px',
      height: '20px',
      position: 'absolute',
      background: '#63a4ff',
      display: 'block',
      content: "",
      borderRadius: '9999px',
      opacity: 1,
      animation: '0.9s ease 1 forwards ripple-effect',
    },
    '& > .content': {
      position: 'relative',
      zIndex: 2,
    }
  },

}))

export const StyledButton = (props) => {
  const {
    children,
    onClick,
    color = "primary",
    size = "md",
    bold,
    disabled = false,
    variant = 'button',
    fullWidth = false,
    tabIndex,
    ...buttonProps
  } = props

  const variantBold = (bold) ? bold : (variant === 'link') ? true : false

  const mounted = React.useRef(true)

  React.useEffect(() => {
    return () => mounted.current = false
  }, [mounted])

  const classes = useStyles()

  const [coords, setCoords] = React.useState({ x: -1, y: -1 })
  const [isRippling, setIsRippling] = React.useState(false)

  const handleClick = e => {
    const rect = e.target.getBoundingClientRect()
    setCoords({ x: e.clientX - rect.left, y: e.clientY - rect.top })
    onClick?.(e)
  }

  React.useEffect(() => {
    let to
    if (coords.x !== -1 && coords.y !== -1) {
      setIsRippling(true)
      to = setTimeout(() => {
        if (mounted.current) setIsRippling(false)
      }, 300)
    } else setIsRippling(false)
    return () => (to) ? clearTimeout(to) : null
  }, [coords])

  React.useEffect(() => {
    if (!isRippling) setCoords({ x: -1, y: -1 })
  }, [isRippling])

  return (
    <button
      className={clsx(
        classes.button, 
        variant === 'button' && classes.asButton,
        variant === 'link' && classes.asLink,
        variant === 'textLink' && classes.asTextLink,
        !disabled && classes.rippleButton,
        size,
        color,
        variantBold && 'bold',
        disabled && 'disabled',
        fullWidth && 'fullWidth',
      )}
      tabIndex={disabled ? "-1" : tabIndex}
      onClick={handleClick}
      {...buttonProps}
    >

      {!disabled && isRippling && (
        <span
          className="ripple"
          style={{
            left: coords.x,
            top: coords.y
          }}
        />
      )}

      <span className="content">{children}</span>

    </button>
  )
}

export const DebouncedButton = (props) => {
  const { 
    debounceTimeout = 500,
    onClick,
    ...buttonProps
  } = props 

  const _handleClick = e => {
    onClick?.(e)
  }

  const handleClick = (debounceTimeout)
    ? debounce(_handleClick, debounceTimeout, {trailing:false, leading:true})
    : _handleClick

  return (
    <StyledButton
      onClick={handleClick}
      {...buttonProps}
    />
  )
}

const Button = (props) => {

  const {
    href = null,
    route = null,
    ...buttonProps
  } = props

  const context = useBlockContext()

  const resolvedHref = (() => {
    if ( route && !href ) {
      try {
        return context.nav.toRoute(route.name, route.params)
      } catch (e) {
        console.warn('Button misconfiguration')
        return null
      }
    } else {
      return href
    }
  })()

  const handleClick = (buttonProps.disabled) ? undefined : (resolvedHref)
    ? e => {
      if (buttonProps.disabled) {
        e.preventDefault()
        e.stopPropagation()
        return null
      } 

      context.nav.history.push(resolvedHref)
    }
    : e => buttonProps?.onClick(e)

  return (
    <DebouncedButton
      {...buttonProps}
      onClick={handleClick ||undefined}
      disabled={(!handleClick && !buttonProps.onClick) || buttonProps.disabled}
    />
  )
}

export default Button
