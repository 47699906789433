import React from 'react'
import { createUseStyles } from 'react-jss'


const useStyles = createUseStyles(theme => ({
  contentBlockContent: props => ({
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    marginTop: theme.space.sm,
    //paddingTop: theme.space.sm,
    paddingBottom: theme.space.sm,
    paddingLeft: theme.space.md,
    paddingRight: theme.space.md,
    whiteSpace: "normal",
    //overflowY: "auto",
    ...props,
  }),
}))

const ContentBlockContent = ({children, ...styleProps}) => {
  const classes = useStyles(styleProps)

  return (
    <div className={classes.contentBlockContent}>
      {children}
    </div>
  )
}

export default ContentBlockContent
