import React from 'react'
import mousetrap from 'mousetrap'
import clsx from 'clsx'
import { createUseStyles } from 'react-jss'
import Draggable from 'react-draggable'

import { usePersistedState } from 'voodoo/hooks'
import ThemeCTX from '../ThemeCTX'


const useToolBoxStyles = createUseStyles({
  toolboxContainer: {
    position: "absolute",
    fontSize: '14px',
    bottom: 10,
    right: 10,
    width: "300px",
    height: "400px",
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    overflow: "hidden",
  },
  toolboxHeader: {
    textAlign: "center",
    cursor: "move",
    backgroundColor: "red",
    borderTopRightRadius: "4px",
    borderTopLeftRadius: "4px",
    border: "2px solid red",
    color: "white",
  },
  toolboxContent: {
    fontSize: ".5rem",
    backgroundColor: "white",
    color: "black",
    flexGrow: 1,
    overflowY: "auto",
    padding: "5px",
    borderBottomRightRadius: "4px",
    borderBottomLeftRadius: "4px",
    borderLeft: "2px solid lightgray",
    borderRight: "2px solid lightgray",
    borderBottom: "2px solid lightgray",
  },
  toolBoxFieldContianer: {
    width: "100%",
    marginBottom: "4px",
  },
})

const ToolBoxField = ({label, children}) => {
  const classes = useToolBoxStyles()
  return (
    <div className={classes.toolBoxFieldContianer}>
      <div>{label}</div>
      <div>
        {children}
      </div>
    </div>
  )
}

const ToolBox = () => {
  const {
    compactness,
    setCompactness,
    setThemeName,
    setUserTheme,
    themeName,
    userTheme,
  } = React.useContext(ThemeCTX)
  const classes = useToolBoxStyles()

  const [toolboxOpen, setToolboxOpen] = usePersistedState('showUiToolbox', false)

  const toggleToolbox = () => {
    setToolboxOpen(!toolboxOpen)
  }

  const toolboxBinding = 'alt+shift+t'

  // TODO: fix mousetrap binding scope
  React.useEffect(() => {
    mousetrap.bind(toolboxBinding, toggleToolbox)
    return () => {
      mousetrap.unbind(toolboxBinding, toggleToolbox)
    }
  })

  const handleThemeSelect = e => {
    setThemeName(e.target.value)
  }
  const handleCompactnessSelect = e => {
    setCompactness(e.target.value)
  }

  if (!toolboxOpen) return null

  return (
    <Draggable handle=".drag-handle">
      <div className={classes.toolboxContainer} >
        <div className={clsx("drag-handle", classes.toolboxHeader)} >
          Tools
        </div>
        <div className={classes.toolboxContent}>
          <ToolBoxField label='Theme'>
            <select value={themeName} onChange={handleThemeSelect}>
              <option value="none">[None]</option>
              <option value="light">Light</option>
              <option value="dark">Dark</option>
            </select>
          </ToolBoxField>
          <ToolBoxField label='Compactness'>
            <select value={compactness} onChange={handleCompactnessSelect}>
              <option value="verySmall">Very Small</option>
              <option value="small">Small</option>
              <option value="normal">Normal</option>
              <option value="large">Large</option>
              <option value="veryLarge">Very Large</option>
            </select>
          </ToolBoxField>
          <ToolBoxField label={'Base: Color'}>
            <input
              value={userTheme.color || ''}
              onChange={(e) => setUserTheme({...userTheme, color:e.target.value})}
            />
          </ToolBoxField>
          <ToolBoxField label={'Base: Background Color'}>
            <input
              value={userTheme.backgroundColor || ''}
              onChange={(e) => setUserTheme({...userTheme, backgroundColor:e.target.value})}
            />
          </ToolBoxField>
        </div>

      </div>
    </Draggable>
  )
}

export default ToolBox
