import React from 'react'
import { createUseStyles } from 'react-jss'


const useStyles = createUseStyles(theme => ({
  contentBlockTitle: props => ({
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    fontSize: theme.size[props.size],
    paddingTop: theme.space.sm,
    paddingBottom: theme.space.sm,
    paddingLeft: theme.space.md,
    paddingRight: theme.space.md,
    ...props,
  }),
}))

const ContentBlockTitle = ({children, ...styleProps}) => {
  const classes = useStyles({size: 'lg', ...styleProps})

  return (
    <div className={classes.contentBlockTitle}>
      {children}
    </div>
  )
}

export default ContentBlockTitle
