import React from 'react'
import { createUseStyles } from 'react-jss'


const useStyles = createUseStyles(theme => ({
  contentBlockCaption: props => ({
    paddingLeft: theme.space.md,
    fontSize: theme.size.sm,
    fontStyle: "oblique",
    opacity: .5,
    whiteSpace: "normal",
    ...props,
  }),
}))

const ContentBlockCaption = ({children, ...styleProps}) => {
  const classes = useStyles(styleProps)

  return (
    <div className={classes.contentBlockCaption}>
      {children}
    </div>
  )
}

export default ContentBlockCaption
