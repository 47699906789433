import React from 'react'


const InputErrors = (props) => {
  const {
    errors=[],
  } = props

  if (errors.length < 1) {
    return null
  }

  return (
    <div className="input-error">
      {errors.map((message, id) => {
        return (
          <div key={id}>
            {message}
          </div>
        )
      })}
    </div>
  )
}

export default InputErrors
