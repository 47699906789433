import React from 'react'
import { useForm } from 'react-hook-form'

import BlockContext from '../BlockContext'
import BlockRenderer from '../BlockRenderer'
import { useBlockContext } from '../hooks'


/**
 * context block wrapping react-hook-form, providing the relevant
 * tools to context at contextKey
 */
const FormContextBlock = (props) => {

  const {
    prepareInitialData,
    initialData={},
    contextKey='form',
    Form=false,
    formProps={},
    onSubmit=() => () => console.log("Unconfigured onSubmit for FormContextBlock"),
    ...passThroughProps
  } = props

  const context = useBlockContext()

  const form = useForm({defaultValues: initialData})

  //const fetchedInitial = prepareInitialData ? prepareInitialData(context) : false

  // allows getting the form hydrated whenever the super context gets around
  // to providing what is wanted by prepareInitialData
  //React.useEffect(() => {
  //if (fetchedInitial !== false && fetchedInitial !== undefined) {
  //form.reset(fetchedInitial)
  //}
  //}, [fetchedInitial]) // eslint-disable-line

  // this seems to work better, re-getting on context change...
  React.useEffect(() => {
    if (typeof prepareInitialData === 'function') {
      form.reset(prepareInitialData(context))
    }
  }, [context]) // eslint-disable-line


  // TODO: this is a hack, should be moved. Parse API validation errors
  // and call setError on the related fields
  const setApiValidationErrors = (errorResponse={}) => {
    const {validationErrors={}} = errorResponse
    if (validationErrors === null || validationErrors === undefined) {
      return
    }
    // FIXME: does not handle array object validation errors
    Object.entries(validationErrors).forEach(([fieldName, messages]) => {
      form.setError(fieldName, {
        type: "manual",
        message: messages,
      })
    })
  }

  // preapre this now, easy to pass via context to elements that need it
  // manually creating a simlar context object as below, so form context 
  // is available in creating submitForm, which will also be added to context
  const submitForm = () => {
    // clear any errors to allow the subit to run again....
    form.clearErrors()
    form.handleSubmit(onSubmit({
      ...context,
      [contextKey]: {
        ...form,
        setApiValidationErrors,
      }
    }))()
  }

  const newCtx = {...context, [contextKey]: { ...form, submitForm }}

  // If Form key is true, render basic form. else, expect component there
  if (Form) {
    const FormComponent = (Form === true) ? 'form' : Form
    return (
      <FormComponent
        style={{width:"100%"}}
        onSubmit={submitForm}
        {...formProps}
      >
        <BlockContext.Provider value={newCtx}>
          <BlockRenderer {...passThroughProps} />
        </BlockContext.Provider>
      </FormComponent>
    )
  }

  // without Form, just render the context, no ui.
  return (
    <BlockContext.Provider value={newCtx}>
      <BlockRenderer {...passThroughProps} />
    </BlockContext.Provider>
  )
}

export default FormContextBlock
