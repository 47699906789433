import { debounce } from 'lodash'

import Input from './components/Input'
import Checkbox from './components/Checkbox'
import InputControl from './components/InputControl'
import InputLabel from './components/InputLabel'
import Select from './components/Select'


/**
 * Filter components
 */
export const SelectFilter = (props) => {
  const {
    label,
    onChange,
    options=[],
    value,
  } = props

  return (
    <InputControl
      variant="outlined"
    >
      <InputLabel>{label}</InputLabel>
      <Select
        options={options}
        value={value}
        onChange={e => onChange(e.target.value)}
      />
    </InputControl>

  )
}

export const CheckboxFilter = (props) => {
  const {
    label,
    checkedValue=true,
    onChange,
    value,
  } = props

  return (
    <span>
      <input
        type="checkbox"
        checked={!!value}
        value={checkedValue}
        onChange={e => onChange(e.target.checked ? checkedValue : null)}
      />
      {label}
    </span>
  )
}

export const MultiCheckboxArrayFilter = (props) => {
  const {
    label,
    value=[],
    onChange,
    options=[]
  } = props

  const handleChange = (field) => e => {
    let id = value.indexOf(field)
    if ( e.target.checked ) {
      if (id < 0) { // not present, add
        onChange([...value, field])
      }

    } else {
      if (id > -1) { // is present, remove
        let newValue = [...value]
        newValue.splice(id, 1)
        onChange(newValue)
      }
    }
  }

  return (
    <InputControl>
      <InputLabel>{label}</InputLabel>
      {options.map((opt, id) => {
        return (
          <InputControl
            key={id}
            fullWidth={false}
            inline
          >
            <Checkbox
              onChange={handleChange(opt.value)}
              checked={!!value.includes(opt.value)}
            />
            <InputLabel>{opt.label}</InputLabel>
          </InputControl>
        )
      })}
    </InputControl>
  )

}

const CommaDelimitedArrayFilter = (props) => {
  const {
    debounceTimeout=500,
    label,
    onChange,
    value=[],
  } = props

  const debouncedUpdate = debounce(e => {
    let asArray = e.target.value.split(",").map(i => i.trim()).filter(i => i.length)
    onChange(asArray)
  }, debounceTimeout)

  const defaultValue = ((Array.isArray(value)) ? value : []).join(", ")

  return (
    <InputControl variant="outlined">
      <InputLabel>{label}</InputLabel>
      <Input
        defaultValue={defaultValue}
        onChange={debouncedUpdate}
      />
    </InputControl>
  )
}

const SpaceDelimitedArrayFilter = (props) => {
  const {
    debounceTimeout=500,
    label,
    onChange,
    value=[],
  } = props

  const debouncedUpdate = debounce(value => {
    let asArray = value.split(" ")
      .map(i => Number(i.trim()))
      .filter(i => !isNaN(i))
    onChange(asArray)
  }, debounceTimeout)

  const defaultValue = ((Array.isArray(value)) ? value : []).join(" ")

  return (
    <InputControl variant="outlined">
      <InputLabel>{label}</InputLabel>
      <Input
        defaultValue={defaultValue}
        onChange={e => debouncedUpdate(e.target.value)}
      />
    </InputControl>
  )
}

export const SearchFilter = (props) => {
  const {
    label="Search",
    debounceTimeout=500,
    onChange,
    value,
    defaultValue='',
    ...inputProps
  } = props

  const debouncedOnChange = debounce(value => onChange(value), debounceTimeout)

  return (
    <InputControl
      variant="outlined"
    >
      <InputLabel>{label}</InputLabel>
      <Input
        type="text"
        onChange={e => debouncedOnChange(e.target.value)}
        defaultValue={value ? value : defaultValue}
        placeholder="Search"
        {...inputProps}
      />
    </InputControl>
  )
}

export const filterMap = {
  SearchFilter,
  CheckboxFilter,
  CommaDelimitedArrayFilter,
  MultiCheckboxArrayFilter,
  NullFilter: () => null,
  SelectFilter,
  SpaceDelimitedArrayFilter,
}
