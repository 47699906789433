import React from 'react'


const config = {
  apps: {
    purchasing: {
      AppComponent: React.lazy(() => import('./App.js')),
      label: "Purchasing",
      basePath: "/purchasing",
      navRoute: "purchasing.orders.list",
      navigation: true,
      navigationSequence: 600,
      routes: {
        dashboard: {
          path: "",
          exact: true,
        },
        orders: {
          path: "/orders",
          exact: false,
          virtual: false,
          routes: {
            list: { path: "", exact: true, },
            create: { path: "/create", exact: true, },
            read: { path: "/:orderId", exact: true, },
            update: { path: "/:orderId/edit", exact: true, },
            receive: { path: "/:orderId/receive", exact: true, }, },
        },
        incomingProducts: {
          path: "/incomingProducts",
          virtual: true,
          routes: {
            list: { path: "", exact: true, },
          },
        },
        vendors: {
          path: "/vendors",
          exact: false,
          virtual: false,
          routes: {
            list: { path: "", exact: true, },
            create: { path: "/create", exact: true, },
            update: { path: "/:contactId/edit", exact: true, },
            read: { path: "/:contactId", exact: true, },
          },
        },
      },
    },
  },
}

export default config
