import React from 'react'
import { createUseStyles } from 'react-jss'


const useStyles = createUseStyles(theme => ({
  contentBlockControls: props => ({
    flexGrow: 0,
    marginTop: theme.space.sm,
    marginBottom: theme.space.sm,
    marginLeft: theme.space.md,
    marginRight: theme.space.md,
    ...props,
  }),
}))

const ContentBlockControls = ({children, ...styleProps}) => {
  const classes = useStyles(styleProps)

  return (
    <div className={classes.contentBlockControls}>
      {children}
    </div>
  )
}

export default ContentBlockControls
