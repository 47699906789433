

const contextReducer = (state, {action, ...payload}) => {

  switch ( action ) {
    default:
      return state
  }

}

export default contextReducer
