import { prepareAppConfig } from 'hoodoo-ng'
import { useHistory, generatePath } from "react-router-dom"

import BlockRenderer from '../BlockRenderer'
import BlockContext from '../BlockContext'


/**
 * block to add initialize the Hoodoo apps and context utilities
 */
const BaseContextBlock = (props) => {

  const {
    appConfigs={},
    applicationName="No Name Here",
    extraContext={},
    ...passThroughProps
  } = props

  const {apps, definedRoutes} = prepareAppConfig(appConfigs)

  const history = useHistory()

  const redirect = (route, params) => {
    history.push(generatePath(resolveRouteName(route).path, params))
  }

  const replace = (route, params) => {
    history.replace(generatePath(resolveRouteName(route).path, params))
  }

  // TODO: resolve  undefined to a 404 route
  const resolveRouteName = (routeName) => {
    return definedRoutes[routeName]
  }

  const toRoute = (routeName, params = {}) => {
    return generatePath(resolveRouteName(routeName).path, params)
  }

  const context = {
    applicationName,
    apps,
    routes: definedRoutes,
    nav: { history, redirect, replace, resolveRouteName, toRoute, generatePath },
    toast: window.toast,
    ...extraContext,
  }

  return (
    <BlockContext.Provider value={context}>
      <BlockRenderer {...passThroughProps} />
    </BlockContext.Provider>
  )
}

export default BaseContextBlock
