import React from 'react'

/**
 * source: https://joshwcomeau.com/react/persisting-react-state-in-localstorage/
 */
const usePersistedState = (key, defaultValue=null) => {
  const [value, setValue] = React.useState(() => {
    const persistedValue = window.localStorage.getItem(key);
    return persistedValue !== null
      ? JSON.parse(persistedValue)
      : defaultValue;
  });
  React.useEffect(() => {
    window.localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);
  return [value, setValue];
}

export default usePersistedState
