import React from 'react'

import HelpContext from './HelpContext'


const helpContextReducer = (state, {action, ...payload}) => {
  switch (action) {
    case "open":
      return {...state, open: true, slug: payload.slug}
    case "close":
      return {...state, open: false}
    default:
      return state
  }
}

const HelpContextProvider = ({children}) => {
  const [state, dispatch] = React.useReducer(helpContextReducer, {
    slug: "home",
    open: false,
  })

  const onOpen = (slug) => {
    dispatch({action: "open", slug})
  }

  const onClose = (slug) => {
    dispatch({action: "close"})
  }

  return (
    <HelpContext.Provider
      value={{...state, onOpen, onClose}}
    >
      {children}
    </HelpContext.Provider>
  )
}
export default HelpContextProvider
