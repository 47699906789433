import React from 'react'
import { useHistory, generatePath } from "react-router-dom"

import Context from './Context'
import contextReducer from './contextReducer'
import getInitialContextState from './getInitialContextState'


const Witchy = (props) => {

  const { routes = [], children } = props

  const routerHistory = useHistory()

  const [context] = React.useReducer(
    contextReducer, {routes}, getInitialContextState
  )

  const resolveRoute = (routeName, params) => {
    let routeDef = context.routes[routeName]

    if (!routeDef) return ''

    return generatePath(routeDef.path, params)
  }

  const toRoute = (routeName, params) => {
    let path = resolveRoute(routeName, params)

    if (!path) {
      console.warn('Misconfigured route for Link')
      return null
    }

    routerHistory.push(path)
  }

  const toRouteHandler = (routeName, params) => () => {
    return toRoute(routeName, params)
  }

  const redirect = (path) => {
    routerHistory.push(path)
  }

  return (

    <Context.Provider value={{
      ...context,
      resolveRoute,
      toRoute,
      toRouteHandler,
      redirect,
    }}>

      {children}

    </Context.Provider>

  )

}

export default Witchy
