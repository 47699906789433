import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import TabHead from './TabHead'

const TabCard = (props) => {
  const {
    headCard=false,
    label,
    useAnchors=false,
    children,
  } = props
  const { hash, pathname } = useLocation()
  const history = useHistory()

  const tabs = React.Children.map(children, c => {
    return {id: c.props.id, label: c.props.tabLabel}
  })
  const tabNames = tabs.map(i => i.id)

  const [activeTab, setActiveTab] = React.useState(tabs[0])

  const handleTabChange = tab => {
    if (useAnchors) {
      history.replace(pathname+"#"+tab.id)
    } 
    setActiveTab(tab)
  }


  // select new tab on hash change
  React.useEffect(() => {
    // nothing to do if anchors disabled
    if (useAnchors) {
      // eslint-disable-next-line
      let [_hash, hashValue] = hash.split("#")
      if (hash && hashValue && hashValue !== activeTab && tabNames.includes(hashValue)) {

        handleTabChange(tabs.filter(i => i.id === hashValue)[0])
      }
    }
  }, [useAnchors, hash]) // eslint-disable-line

  return (
    <div>
      <TabHead
        key={activeTab.id}
        card={headCard}
        label={label}
        tabs={tabs}
        activeTab={activeTab}
        onSelectTab={handleTabChange}
      />
      {React.Children.map(children, c => {
        return React.cloneElement(c, {active: c.props.id === activeTab.id})
      })}
    </div>
  )

}

export default TabCard
