
export const maybeFunc = (value, ...args) => {
  if (typeof value === 'function') {
    return value(...args)
  }
  return value
}

export const msTextualRelativeTime = (ms, decimals=0) => {
  // adapted (copied) from https://stackoverflow.com/a/32180863

  const seconds = (ms / 1000).toFixed(decimals)
  const minutes = (ms / (1000 * 60)).toFixed(decimals)
  const hours = (ms / (1000 * 60 * 60)).toFixed(decimals)
  const days = (ms / (1000 * 60 * 60 * 24)).toFixed(decimals)

  if (seconds < 60) return seconds + "s"

  else if (minutes < 60) return minutes + "m"

  else if (hours < 24) return hours + "h"

  else return days + "d"
}
