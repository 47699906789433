import React from 'react'


const hoodooConfig = {
  apps: {
    dashboard: {
      AppComponent: React.lazy(() => import('./App.js')),
      basePath: "/",
      basePathExact: true,
      label: "Dashboard",
      navRoute: 'dashboard.dashboard',
      navigation: true,
      navigationSequence: 100,
      routes: {
        dashboard: {
          path: "",
          exact: true,
        },
      },
    },
  },
}

export default hoodooConfig
