import React from 'react'
import { createUseStyles } from 'react-jss'


const useStyles = createUseStyles(theme => ({
  contentBlockHead: props => ({
    flexGrow: 0,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    ...props,
  }),
}))

const ContentBlockHead = ({children, ...styleProps}) => {
  const classes = useStyles(styleProps)

  return (
    <div className={classes.contentBlockHead}>
      {children}
    </div>
  )
}

export default ContentBlockHead
