import React from 'react'
import color from 'color'
import clsx from 'clsx'
import { createUseStyles } from 'react-jss'


const useStyles = createUseStyles(theme => ({
  contentBlock: props => ({
    display: "flex",
    textAlign: "left",
    fontSize: theme.size.md,
    flexDirection: "column",
    //overflow: "hidden",
    ...props,
  }),
  card: {
    boxShadow: `1px 1px 6px -1px ${color(theme.colors.primary.color).alpha(.3).hsl()}`,
    color: theme.colors.default.color,
    backgroundColor: theme.colors.default.backgroundColor,
    '&.transparent': {
      backgroundColor: color(theme.colors.default.backgroundColor).alpha(.9).rgb().toString(),
    },
  },
  marginedBlock: {
    marginTop: theme.space.sm,
    marginBottom: theme.space.sm,
    marginLeft: theme.space.sm,
    marginRight: theme.space.sm,
  },
}))

const ContentBlock = (props) => {
  const {
    card=false,
    transparent=false,
    children,
    noMargins=false,
    ...styleProps
  } = props

  const classes = useStyles(styleProps)

  return (
    <div className={clsx(
      classes.contentBlock,
      card && classes.card,
      transparent && 'transparent',
      noMargins !== true  && classes.marginedBlock,
      )}>
      {children}
    </div>
  )
}

export default ContentBlock
