import devkit from './devkit/config'
import config from './config/config'
import dashboard from './dashboard/config'
import inventory from './inventory/config'
import manufacturing from './manufacturing/config'
import fulfillment from './fulfillment/config'
import profile from './profile/config'
import purchasing from './purchasing/config'
import sales from './sales/config'
import catalog from './catalog/config'
//import contacts from './contacts/config'
import accounting from './accounting/config'
import users from './users/config'
//import content from './content/config'
import payroll from './payroll/config'

const defExports = [
  devkit,
  config,
  dashboard,
  inventory,
  manufacturing,
  fulfillment,
  profile,
  purchasing,
  sales,
  catalog,
  //contacts,
  accounting,
  users,
  //content,
  payroll
]

export default defExports
