import React from 'react'
import downloadFile from 'downloadjs'
import config from 'config'

import bartholomew from 'bartholomew'
import { bartholomewCompat as legbe } from 'apis/legbe'

import { ContextContainer, useWitchy } from 'witchy'


const authenticator = (() => {

  if (process.env.REACT_APP_FEATURE_LEGBE_ENABLE === "1") {
    return legbe
  }

  return bartholomew

})()

export const serverBaseURL = `${config.API_PROTOCOL}://${config.API_HOST}${(config.API_PORT)?":"+config.API_PORT:''}${config.API_BASEPATH}`

export const serverCheckUrl = `${serverBaseURL}/up`

export const chwalURL = `${serverBaseURL}/chwal`

export const chwalDownloadURL = chwalURL+"/downloads"

export const tokenStorageKey = authenticator.tokenStorageKey

const getWebTokenHeaderContent = () => {
  let token = localStorage.getItem(tokenStorageKey)
  if (token) {
    return `Bearer ${token}`
  }
  return ''
}

export const openDownload = async (resourceId, filename, filetype) => {
  // compose body, overwrite payload and path
  let body = {id: resourceId}
  try {
    let resp = await fetch(chwalDownloadURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getWebTokenHeaderContent(),
      },
      body: JSON.stringify(body),
    })

    if (!resp.ok) {} // ignore bad request codes

    let blob = await resp.blob()
    let file = new File([blob], filename, {type: filetype})


    let url = URL.createObjectURL(file)
    window.open(url, '_blank')

  } catch (e) {
    console.log("API Fetch Error:", e)
  }
}

export const download = async (resourceId, filename, filetype) => {
  // compose body, overwrite payload and path
  let body = {id: resourceId}
  try {
    let resp = await fetch(chwalDownloadURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getWebTokenHeaderContent(),
      },
      body: JSON.stringify(body),
    })

    if (!resp.ok) {} // ignore bad request codes

    let blob = await resp.blob()

    
    downloadFile(blob, filename, filetype)

  } catch (e) {
    console.log("API Fetch Error:", e)
  }
}

export const checkServer = async () => {
  try {
    let resp = await fetch(serverCheckUrl)
    if (!resp.ok) {
      console.log("Error connecting to voodoo:", resp.statusText)
      return {
        success: false,
      }
    } else {
      let data = await resp.json()
      return {
        success: true,
        ...data
      }
    }
  } catch (e) {
    console.log("Error connecting to voodoo:", e)
    return {
      success: false,
    }
  }
}

export const call = async (path, payload={}, extra={}) => {
  // compose body, overwrite payload and path
  let body = { ...extra, payload, path}

  try {
    let resp = await fetch(chwalURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getWebTokenHeaderContent(),
      },
      body: JSON.stringify(body),
    })

    if (!resp.ok) {} // ignore bad request codes

    let data = await resp.json()

    return data

  } catch (e) {
    console.log("API Fetch Error:", e)
  }
}

export const batch = async (batchCalls=[]) => {
  if (!batchCalls.length) return null
  // compose body, overwrite payload and path
  //path, payload={}, extra={}
  //let body = { ...extra, payload, path}

  let body = batchCalls.map(c => {
    let {path, payload={}, extra={}} = c
    return {path, ...extra, payload}
  })


  try {
    let resp = await fetch(chwalURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getWebTokenHeaderContent(),
      },
      body: JSON.stringify(body),
    })

    if (!resp.ok) {} // ignore bad request codes

    let data = await resp.json()

    return data

  } catch (e) {
    console.log("BATCH API Fetch Error:", e)
  }
}

export const useCall = (path, payload, extra, defaultData=null) => {
  const mounted = React.useRef(true)

  const [state, setState] = React.useState({
    data: defaultData,
    loading: true,
    error: null
  })

  const fetchData = async () => {
    if (mounted.current) {
      await setState(s => ({...state, loading: true}))
    }

    let {data, error} = await call(path, payload, extra)

    if (mounted.current) {
      setState(s => ({...state, data, error, loading: false}))
    }
  }

  React.useEffect(() => {
    fetchData()

    return () => mounted.current=false
  }, [path, payload, extra]) // eslint-disable-line

  return {
    data: state.data,
    error: state.error,
    loading: state.loading,
    refetch: () => fetchData() 
  }
}

export const useSingle = (path, payload, extra) => {
  return useCall(path, payload, extra, {})
}

export const useList = (path, payload, extra) => {
  return useCall(path, payload, extra, [])
}

const chwalEndpointReducer = (state, {action, ...payload}) => {

  switch(action) {
    case 'getData':
      return {...state, loading: true, shouldGetData: true}

    case 'receiveData':
      return {...state, loading: false, shouldGetData: false, data: payload.data, error:payload.error}

    default:
      return state
  }

}

export const useChwalEndpoint = (path, payload={}, extra={}, defaultData={}) => {

  const mounted = React.useRef(false)
  
  const [
    {data, error, shouldGetData}, dispatch
  ] = React.useReducer(chwalEndpointReducer, {
    data: defaultData,
    error: null,
    loading: true,
    shouldGetData: true,
  })

  const getData =  async () => {
    const resp = await call(path, payload, extra)

    if (mounted.current) {
      dispatch({action: 'receiveData', ...resp})
    }
  }

  React.useEffect(() => {
    if (shouldGetData === false && mounted.current) {
      dispatch({action: 'getData'})
    }
  }, [path, payload, extra]) // eslint-disable-line

  React.useEffect(() => {
    mounted.current = true
    if (shouldGetData) getData()
    return () => mounted.current = false
  }, [shouldGetData]) // eslint-disable-line

  return {
    data, error,
    refetch: getData,
  }

}

export const setChwalValidationErrors = (form, error) => {
  let { validationErrors } = error 

  // nothing to see here
  if (!validationErrors) return null

  Object.entries(validationErrors).forEach(([fieldName, messages=[]]) => {

    // Check if this is a subField (arrayField) group
    // quirky, but this is how the chawl validatejs formats these
    if (messages.length && !Array.isArray(messages[0]) && typeof messages[0] === 'object') {

      // recompose subfield messages as "flat" field messages
      let subFieldMessages = Object.entries(messages[0].errors)
        .reduce((acc, [key, errorObj]) => {
          Object.entries(errorObj.error).forEach(([subField, subFieldMessages]) => {
            acc[`${fieldName}.${key}.${subField}`] = subFieldMessages
          })
          return acc
        }, {})

      // recurse, monkey style
      setChwalValidationErrors(form, {validationErrors: subFieldMessages})

    } else {
      // react-hook-form uses single message fields, but realistically, field
      // errors may have multiple problems, as validatejs supports, so despite
      // the confusion over singlular and plural names, we assign an array here
      form.setError(fieldName, {
        type: 'manual',
        message: messages,
      })
    }
  })
}

const initialListContextState = (initialState) => {
  return {
    listData: [],

    hasFetchedInitialList: false,
    shouldFetchList: false,

    filterValues: initialState.filterValues || {},

    page: 1,
    pageSize: 50,
    count: null,
    firstRecord: null,
    lastRecord: null,
  }
}

const listContextReducer = (state, { type, ...payload }) => {

  switch(type) {

    case "receiveListData": 
      // prepare basic state
      let { data, meta={} } = payload

      let newState = {
        ...state,
        listData: data,
      }

      if (state.shouldFetchList === true) {
        newState.shouldFetchList = false
      }

      if (state.hasFetchedInitialList === false) {
        newState.hasFetchedInitialList = true
      }

      // prepare pagination
      if (meta.count) {
        newState.count = Number(meta.count)
        newState.firstRecord = (state.page - 1) * state.pageSize + 1
        newState.lastRecord = newState.firstRecord - 1 + data.length
      } else {
        newState.count = null
      }
      return newState

    case "setShouldFetch":
      return {...state, shouldFetchList: true}

    case "setFilterValue":
      return {
        ...state,
        shouldFetchList: true,
        filterValues: {...state.filterValues, [payload.filterKey]: payload.value}
      }

    case 'nextPage':
      if ( (state.page ) * state.pageSize < state.count ) {
        return {...state,
          shouldFetchList: true,
          page: state.page+1,
        }
      } else {
        return state
      }

    case 'prevPage':
      if ( state.page > 1 ) {
        return {...state,
          shouldFetchList: true,
          page: state.page-1,
        }
      } else {
        return state
      }

    case 'goToPage':
      return {...state,
        shouldFetchList: true,
        page: payload.page,
      }

    case 'setPageSize':
      return {...state,
        shouldFetchList: true,
        pageSize: payload.pageSize,
        page: 1,
      }
    default:
      return state
  }
}

export const ListContext = (props) => {
  let {
    path, payload = {}, filters = {},
    contextKey = 'listContext',
    dataKey = 'listData',
    ...contextProps
  } = props

  const [listState, dispatch] = React.useReducer(
    listContextReducer,
    {filterValues: filters},
    initialListContextState
  )

  const fetchData = async () => {

    let paginationFilters = {
      limit: listState.pageSize,
      offset: (listState.page - 1) * listState.pageSize,
    }

    let resp = await call(
      path,
      payload,
      {filters: {
        ...listState.filterValues,
        ...paginationFilters,
      }}
    )

    dispatch({type: "receiveListData", ...resp})

  }

  const _lastPage = (listState.count !== null && listState.pageSize > 0)
    ? Math.ceil(listState.count / listState.pageSize)
    : null

  const updateFilterValue = filterKey => value => {

    dispatch({type: "setFilterValue", filterKey, value})

  }

  React.useEffect(() => {
    if (!listState.hasFetchedInitialList) fetchData()
  }, [listState.hasFetchedInitialList]) // eslint-disable-line

  React.useEffect(() => {
    if (listState.shouldFetchList) fetchData()
  }, [listState.shouldFetchList]) // eslint-disable-line

  return (

    <ContextContainer
      prepared={listState.hasFetchedInitialList}
      {...{
        [contextKey]: {
          ...listState,
          refetch: fetchData,
          updateFilterValue,
          nextPage: () => dispatch({type: "nextPage"}),
          previousPage: () => dispatch({type: "prevPage"}),
          firstPage: () => dispatch({type: "goToPage", page: 1}),
          lastPage: () => dispatch({type: "goToPage", page: _lastPage}),
          setPageSize: (pageSize) => dispatch({type: 'setPageSize', pageSize}),
        },
        [dataKey]: listState.listData,
      }}
      {...contextProps}
    />

  )
}

const initialSingleContextState = (intitialState) => {
  return {
    data: {},
    hasFetchedInitialItem: false,
    shouldFetchItem: false,
  }
}

const singleContextReducer = (state, { type, ...payload }) => {
  switch(type) {
    case 'setShouldFetchItem':
      return {...state, shouldFetchItem: true}

    case 'receiveData':
      return {...state, hasFetchedInitialItem: true, data: payload.data, shouldFetchItem: false}

    default:
      return state
  }
}

// TODO: fix failure to re-render on path changes...
export const SingleContext = (props) => {
  let {
    path, payload = {}, extra = {},
    contextKey = 'singleContext',
    dataKey = 'singleData',
    ...contextProps
  } = props

  const ctx = useWitchy()

  const { params } = ctx

  const fetchData = async () => {
    let callPayload = (() => {
      if ( typeof payload === 'function' ) {
        return payload({params})
      } else {
        return payload
      }
    })()

    let callExtra = (() => {
      if ( typeof extra === 'function' ) {
        return extra({params})
      } else {
        return extra
      }
    })()

    let resp = await call(path, callPayload, callExtra)

    dispatch({type: 'receiveData', ...resp})

  }

  const [state, dispatch] = React.useReducer(singleContextReducer, undefined, initialSingleContextState)

  React.useEffect(() => {
    if (!state.hasFetchedInitialItem || state.shouldFetchItem) {
      fetchData()
    }
  }, [state.hasFetchedInitialItem, state.shouldFetchItem]) // eslint-disable-line

  React.useEffect(() => {
    dispatch({type: "setShouldFetchItem"})
  }, [dispatch, params])

  return (

    <ContextContainer
      prepared={state.hasFetchedInitialItem}
      {...{
        [contextKey]: {
          ...state,
          refetch: fetchData,
        },
        [dataKey]: state.data,
      }}
      {...contextProps}
    />

  )

}

const defExports = {
  call,
  url: chwalURL,
  useCall,
  useList,
  useSingle,
}

export default defExports
