import color from 'color'

const theme = {
  fontFamily: "serif",
  backgroundColor: color('white').darken(.99).hex(),
  colors: {
    default: {
      color: color('white').darken(.4).hex(),
      backgroundColor: color('white').darken(.9).hex(),
      offset: 'black',
    },
    primary: {
      color: color('purple').darken(.25).hex(),
      backgroundColor: color('white').darken(.3).hex(),
      offset: 'white',
    },
    secondary: {
      color: color('white').darken(.3).hex(),
      backgroundColor: color('white').darken(.8).hex(),
      offset: 'black',
    },
    success: {
      color: color('green').darken(.3).hex(),
    },
    warning: {
      color: color('orange').darken(.3).hex(),
    },
    danger: {
      color: color('red').darken(.3).hex(),
    },
  },
}

export default theme
