/**
 * Witchy - Layout and flow manager
 *
 * Basic design principles and needs:
 *  - Defined "views", theselves self contained beyond passed props
 *  - Views can be rendered anywhere at any time
 *  - Inteneral "history", which stacks both the location AND props
 *  - Integrated "breadcrumb" approach, so the history is visible
 *    and re-entrant, even if the previous views are not rendered visibly
 *  - Should be able to function discretely in the existing UI, or take over
 *    rendering from the very root
 *  - As it is aware of the view context at all times, should pass useful
 *    methods to each view (e.g, a back() or previous() to go to the last
 *    rendered view, usefull when a particular view is reached from 
 *    multiple routes through the UI nav)
 *
 * Big considerations:
 *  - How to override/replace the history component?
 *  - Can/Should query params be integrated?
 *  - How/Can we used the history location.state storage?
 */

export { default as Route } from './Route'
export { default as Switch } from './Switch'
export { default as Context } from './Context'
export { default as contextReducer } from './contextReducer'
export { default as ContextContainer } from './ContextContainer'
export { default as useSearchParams } from './useSearchParams'
export { default as useWitchy } from './useWitchy'
export { default as withWitchy } from './withWitchy'

// Default export
export { default } from './Witchy'
