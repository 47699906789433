import React from 'react'
import clsx from 'clsx'
import color from 'color'
import { createUseStyles } from 'react-jss'


const useStyles = createUseStyles(theme => ({
  dropdown: {
    display: "inline-block",
    width: "fit-content",
  },
  button: {
    position: "relative",
  },
  menu: {
    position: "absolute",
    top: "105%",
    right: "0px",
    boxShadow: `1px 1px 6px -1px ${color(theme.colors.primary.color).alpha(.3).hsl()}`,
    backgroundColor: theme.colors.default.offset,
    color: theme.colors.default.color,
    width: "fit-content",
    zIndex: 100,
  },

  menuItem: {
    paddingTop: theme.space.xs,
    paddingBottom: theme.space.xs,
    paddingLeft: theme.space.md,
    paddingRight: theme.space.md,
    textAlign: "left",
    whiteSpace: "nowrap",
  },
  clickable: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.colors.primary.color,
      color: theme.colors.primary.offset,
    }
  }
}))

const Action = (props) => {
  const classes = useStyles()

  let {
    label='',
    heading=false,
    action=undefined,
    actions=[],
  } = props

  return (
    <div
      className={clsx(classes.menuItem, (action && !heading) && classes.clickable)}
      onClick={action}
    >
      {label}
      {actions.length > 0 && (
        <hr />
      )}
      {actions.length > 0 && actions.map((action, key) => (
        <Action key={key} {...action} />
      ))}
    </div>
  )
}

const ActionDropdown = (props) => {

  const {
    label=undefined,
    actions=[],
    children,
  } = props

  const ref = React.useRef()

  const [open, setOpen] = React.useState(false)

  const classes = useStyles()

  const escAwayHandler = e => {
    if (open && e.key === 'Escape') {
      setOpen(false)
    }
  }

  const clickAwayHandler = e => {
    if (open && ref.current && !ref.current.contains(e.target)) {
      setOpen(false)
    }
  }

  const toggleOpen = e => {
    setOpen(!open)
  }

  React.useEffect(() => {
    document.addEventListener('click', clickAwayHandler)
    document.addEventListener('keydown', escAwayHandler)
    return () => {
      document.removeEventListener('click', clickAwayHandler)
      document.removeEventListener('keydown', escAwayHandler)
    }
  })

  return (
    <div ref={ref} className={classes.dropdown}>
      <div
        className={clsx(classes.button, classes.clickable)}
        onClick={toggleOpen}
      >
        {label ? label : children}

        {open && (
          <div className={classes.menu}>
            {actions.map((action, key) => {
              return (
                <Action key={key} {...action} />
              )
            })}
          </div>
        )}
      </div>
    </div>
  )
}

export default ActionDropdown
