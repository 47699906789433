import React from 'react'
import { createUseStyles } from 'react-jss'

import { faAngleRight as NextIcon } from '@fortawesome/free-solid-svg-icons'
import { faAngleLeft as PrevIcon } from '@fortawesome/free-solid-svg-icons'
import { faAngleDoubleRight as LastIcon } from '@fortawesome/free-solid-svg-icons'
import { faAngleDoubleLeft as FirstIcon } from '@fortawesome/free-solid-svg-icons'
import { faRedo as RefreshIcon } from '@fortawesome/free-solid-svg-icons'

import ActionButton from './ActionButton'
import InputControl from './InputControl'
import Select from './Select'

const useStyles = createUseStyles(theme => ({
  paginationContainer: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    '& > *': {
      marginRight: theme.space.xxs,
    }
  },
}))

const PaginationControls = (props) => {
  const {
    page,
    count,
    firstRecord,
    lastRecord,
    pageSize,
    previousPage,
    nextPage,
    firstPage,
    lastPage,
    setPageSize,
    refetch,
  } = props

  const classes = useStyles()

  if (!count || count < 1) {
    return null
  }

  return (
    <div className={classes.paginationContainer}>
      <ActionButton
        icon={FirstIcon}
        size="lg"
        variant="link"
        onClick={firstPage}
        disabled={page && page <= 1}
      />
      <ActionButton
        icon={PrevIcon}
        size="lg"
        variant="link"
        onClick={previousPage}
        disabled={page <= 1}
      />
      <div>
        {firstRecord} to {lastRecord} of {count}
      </div>
      <ActionButton
        icon={NextIcon}
        size="lg"
        variant="link"
        onClick={nextPage}
        disabled={!(page && (page * pageSize < count))}
      />
      <ActionButton
        icon={LastIcon}
        size="lg"
        variant="link"
        onClick={lastPage}
        disabled={page && (page >= Math.ceil(count / pageSize))}
      />
      <InputControl inline fullWidth={false}>
        <Select
          defaultValue={pageSize}
          style={{width: "fit-content"}}
          options={[
            {value: 25, label: "25"},
            {value: 50, label: "50"},
            {value: 100, label: "100"},
            {value: 200, label: "200"},
            {value: 500, label: "500"},
          ]}
          onChange={e => setPageSize(e.target.value)}
        />
      </InputControl>
      <ActionButton
        icon={RefreshIcon}
        color="primary"
        variant="link"
        size="md"
        onClick={refetch}
      />
    </div>
  )
}

export default PaginationControls
