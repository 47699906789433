import React from 'react'
import { createUseStyles } from 'react-jss'
import { breakUpMediaQueries } from 'ui/layout/GlobalStyles'


const useStyles = createUseStyles(theme => ({
  helpPanelWrapper: {
    //backgroundColor: "red",
    position: "absolute",
    right: "0px",
    top: "15%",
    bottom: "5%",
  },
  helpPanelBodyWrapper: {
    height: "100%",
    [breakUpMediaQueries['xs']]: {
      width: "100vw",
    },
    [breakUpMediaQueries['md']]: {
      width: "50vw",
    },
    [breakUpMediaQueries['xl']]: {
      width: "40vw",
    },
    //backgroundColor: "blue",
    //margin: theme.space.lg,
  },
  helpPanelContentWrapper: {
    height: "100%",
    //margin: theme.space.lg,
    //marginRight: "-10px",
  }
}))

const HelpPanel = React.forwardRef((props, ref) => {

  const {
    children,
  } = props

  const classes = useStyles()

  return (
    <div ref={ref} className={classes.helpPanelWrapper}>
      <div className={classes.helpPanelBodyWrapper}>
        <div className={classes.helpPanelContentWrapper}>
              {children}
        </div>
      </div>
    </div>
  )
})

export default HelpPanel
