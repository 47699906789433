import React from 'react'
import clsx from 'clsx'
import { createUseStyles } from 'react-jss'


const useStyles = createUseStyles(theme => ({
  inputLabel: {
  }
}))

const InputLabel = (props) => {
  const classes = useStyles()

  const {
    children,
  } = props

  return (
    <div className={clsx(classes.inputLabel, 'input-label')}>
      {children}
    </div>
  )
}

export default InputLabel
