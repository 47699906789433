import { merge } from 'lodash'


const defaultHoodooContext = {
  apps: {},
}

const defaultHoodooAppConfig = {
  route: {
    path: "",
    exact: false,
  },
}

/**
 * attempt to combind all provided apps with sane defaults and structure
 */
export const prepareAppConfig = (apps) => {

  // initial merge of defaults and provided defs into new object
  const preparedConfig = merge({}, defaultHoodooContext, ...apps)

  const flattenRoutes = (routes={}, basePath, baseKey='', flattened={}) => {

    for (const [routeName, routeConfig] of Object.entries(routes)) {
      const currentKey = (baseKey.length) ? [baseKey, routeName].join(".") : routeName

      // if virtual, it's just used to structure/group paths
      // if no path, theres probably something wrong, so skip
      if(!routeConfig.virtual && routeConfig.path !== null && routeConfig.path !== undefined) {
        let currentPath = basePath + routeConfig.path
        flattened[currentKey] = {path: currentPath, exact: routeConfig.exact === undefined ? true : routeConfig.exact}
      }

      // if we have sub routes, flatten those as well, obviously
      if (routeConfig.routes) {
        flattenRoutes(routeConfig.routes, basePath + routeConfig.path, currentKey, flattened)
      }
    }

    return flattened
  }

  // some after the fact defaults and conveninces work
  for(const [appName, appConfig] of Object.entries(preparedConfig.apps)) {

    // set defaults for app config
    preparedConfig.apps[appName] = merge({}, defaultHoodooAppConfig, appConfig)

    // generate flattened dot notated route list
    preparedConfig.apps[appName].routes = flattenRoutes(
      appConfig.routes, appConfig.basePath, appName
    )
  }

  // flatt array of all defined routes assigned to the core config object
  preparedConfig.definedRoutes = merge(
    {}, ...Object.values(preparedConfig.apps).map(a => a.routes)
  )

  // hopefully this objecct now contains sane config objects for configured apps
  return preparedConfig
}

/**
 * quickly handle config keys that can optionally be functions
 */
export const maybeFunc = (suspect, ...args) => {
  if (typeof suspect === 'function') {
    return suspect(...args)
  }
  return suspect
}
