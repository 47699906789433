import React from 'react'
import clsx from 'clsx'


const TableRow = (props) => {

  const {
    onClick,
    children,
    active,
    ...rowProps
  } = props

  return (

    <tr className={clsx(
        onClick !== undefined && 'clickable',
        active && 'active',
      )}
      onClick={onClick}
      {...rowProps}
    >
      {children}
    </tr>

  )

}

export default TableRow
