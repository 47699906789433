import React from 'react'


const config = {
  apps: {
    config: {
      AppComponent: React.lazy(() => import('./App.js')),
      basePath: "/config",
      navRoute: "config.home",
      navigation: true,
      requireRole: "admin",
      navigationSequence: 950,
      label: "Config",
      routes: {
        home: {
          path: "/core",
          exact: true,
        },
        magento: {
          path: "/magento",
          exact: true,
        },
        woocommerce: {
          path: "/woocommerce",
          exact: true,
        },
        gmail: {
          path: "/gmail",
          exact: true,
        },
        content: {
          path: "/content",
          exact: true,
        },
        sequences: {
          path: "/sequences",
          exact: true,
        },
      },
    },
  },
}

export default config
