import React from 'react'


const config = {
  apps: {
    profile: {
      AppComponent: React.lazy(() => import('./App.js')),
      basePath: "/profile",
      navRoute: "profile",
      navigation: false,
      label: "My Profile",
      routes: {
        home: {
          path: "",
          exact: true,
        },
      },
    },
  },
}

export default config
