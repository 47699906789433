const theme = {
  fontFamily: "sans-serif",
  colors: {
    default: {
      color: 'black',
      offset: 'white'},
    primary: {
      color: 'lightblue',
      offset: 'black'},
    secondary: {
      color: 'lightgray',
      offset: 'black'},
    danger: {
      color: 'red',
      offset: 'white'},
    warning: {
      color: 'orange',
      offset: 'black'},
    success: {
      color: 'green',
      offset: 'white'},
    disabled: {
      color: 'darkgray',
      offset: 'white'},
  },
  sizeAndSpace: {
    verySmall: {
      textBase: 9,
      textResponsiveBase: .2,
      spaceBase: 3,
      spaceResponsiveBase: .2,
    },
    small: {
      textBase: 10,
      textResponsiveBase: .2,
      spaceBase: 5,
      spaceResponsiveBase: .2,
    },
    normal: {
      textBase: 12,
      textResponsiveBase: .2,
      spaceBase: 7,
      spaceResponsiveBase: .3,
    },
    large: {
      textBase: 14,
      textResponsiveBase: .2,
      spaceBase: 8,
      spaceResponsiveBase: .3,
    },
    veryLarge: {
      textBase: 16,
      textResponsiveBase: .1,
      spaceBase: 9,
      spaceResponsiveBase: .4,
    },
  },
}

export default theme
