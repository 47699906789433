import React from 'react'
import { useParams, useLocation } from "react-router-dom"

import BlockRenderer from '../BlockRenderer'
import BlockContext from '../BlockContext'


/**
 * block to add or alter context in the block render tree
 */
const ViewContextBlock = (props) => {

  const {
    route,
    ...passThroughProps
  } = props

  const blockCtx = React.useContext(BlockContext)
  const params = useParams() 
  const location = useLocation()

  const newCtx = {
    ...blockCtx,
    view: {
      route,
      params,
    },
  }

  /**
   * pretyy hacky... ensures the block re-renders when the location changes
   *
   * catches issues where the :id changes, but the block doesn't
   * re-render because it's getting params by config, not by state/props
   */
  const LocationAwareBlockRenderer = ({location, blockProps}) => {
    return ( <BlockRenderer {...blockProps} /> )
  }

  return (
    <BlockContext.Provider value={newCtx}>
      <LocationAwareBlockRenderer location={location} blockProps={passThroughProps} />
    </BlockContext.Provider>
  )
}

export default ViewContextBlock
