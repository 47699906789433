import React from 'react'
import { isEmpty } from 'lodash'
import { createUseStyles } from 'react-jss'

import BlockRenderer from 'hoodoo/BlockRenderer'
import ActionsContainer from '../components/ActionsContainer'

import AppNav from '../components/AppNav'


const useStyles = createUseStyles(theme => ({
  appWrapper: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    width: "100%",
    height: "100%",
  },
  appHead: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    fontSize: theme.size.md,
    padding: theme.space.md,
    color: theme.colors.secondary.color,
    backgroundColor: theme.colors.secondary.backgroundColor,
  },
  appHeadTitle: {
    fontSize: theme.size.xl,
  },
  appHeadLeft: {
    flexGrow: 1,
    '& > *': {
      boxSizing: "border-box",
      paddingLeft: theme.space.lg,
    }
  },
  appHeadRight: {

  },
  appContent: {
    display: "flex",
    flexGrow: "1",
    overflow: "auto"
  },
}))

const NavBarLayoutBlock = (props) => {
  const classes = useStyles()

  const { 
    label,
    navBarLinks,
    actions,
    ...passThroughProps
  } = props

  return (
    <div className={classes.appWrapper}>
      <div className={classes.appHead}>
        <div className={classes.appHeadTitle}>
          {label}
        </div>
        <div className={classes.appHeadLeft}>
          <AppNav links={navBarLinks} />
        </div>
        <div className={classes.appHeadRight}>
          {!isEmpty(actions) && (
            <ActionsContainer actions={actions} />
          )}
        </div>
      </div>

      <div className={classes.appContent}>
        <BlockRenderer {...passThroughProps} />
      </div>
    </div>
  )
}

export default NavBarLayoutBlock
