import React from 'react'


const hoodooConfig = {
  apps: {
    inventory: {
      AppComponent: React.lazy(() => import('./App.js')),
      basePath: "/inventory",
      navRoute: 'inventory.stocks.list',
      navigation: true,
      navigationSequence: 300,
      label: "Inventory",
      routes: {
        dashboard: {
          path: "",
          exact: true,
        },
        stocks: {
          path: "/stocks",
          virtual: true,
          routes: {
            list:   { path: '', exact: true, },
            create: { path: '/create', exact: true, },
            read:   { path: '/:stockId', exact: true, },
            update:   { path: '/:stockId/edit', exact: true, },
          },
        },
        locations: {
          path: "/locations",
          virtual: true,
          routes: {
            list:   { path: '', exact: true, },
            create: { path: '/create', exact: true, },
            read:   { path: '/:locationId', exact: true, },
            update:   { path: '/:locationId/edit', exact: true, },
          },
        },
        adjustments: {
          path: "/adjustments",
          virtual: true,
          routes: {
            list:   { path: '', exact: true, },
            create: { path: '/create', exact: true, },
            read:   { path: '/:adjustmentId', exact: true, },
            update:   { path: '/:adjustmentId/edit', exact: true, },
          },
        },
        stockRules: {
          path: "/stockRules",
          virtual: true,
          routes: {
            list:   { path: '', exact: true, },
            create: { path: '/create', exact: true, },
            read:   { path: '/:stockRuleId', exact: true, },
            update:   { path: '/:stockRuleId/edit', exact: true, },
          },
        },
        reports: {
          path: "/reports",
          virtual: false,
        },
      },
    },
  },
}

export default hoodooConfig
