import React from 'react'
import { useParams } from 'react-router-dom'

import Context from './Context'
import useSearchParams from './useSearchParams'


const useWitchy = () => {

  const ctx = React.useContext(Context)
  const query = useSearchParams()
  const params = useParams()

  return {...ctx, params, query}

}

export default useWitchy
