import React from 'react'


const config = {
  apps: {
    users: {
      AppComponent: React.lazy(() => import('./App.js')),
      basePath: "/users",
      navRoute: "users.list",
      navigation: true,
      requireRole: 'admin',
      navigationSequence: 900,
      label: "Users",
      routes: {
        list: { path: "", exact: true },
        create: { path: "/new", exact: true },
        update: { path: "/:userId/edit", exact: true },
        read: { path: "/:userId", exact: true },
      },
    },
  },
}

export default config
