import React from 'react'

import Button from 'hoodoo/components/Button'


const TabLink = (props) => {
  const {
    label = '',
    active = false,
    onClick,
  } = props

  return (
    <Button style={{textDecoration: (active) ? "underline" : "none"}} onClick={onClick} size="md" variant="link" color={active ? "primary" : "default"}>{label}</Button>
  )
}

export default TabLink
