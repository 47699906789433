import React from 'react'


const Select = (props, ref) => {
  const {
    children,
    options=[],
    ...inputProps
  } = props

  return (
    <select ref={ref} {...inputProps}>
      {options.map(({value, label}, id) => (
        <option key={value} value={value}>{label}</option>
      ))}
    </select>
  )
}

export default React.forwardRef(Select)
