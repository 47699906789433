import React from 'react'

import NavLink from './NavLink'

import { useBlockContext } from 'hoodoo/hooks'
import { useAppContext } from 'voodoo/hooks'


const BaseNav = () => {
  const ctx = useBlockContext()

  const {apps} = ctx
  const { userRoles=[] } = useAppContext()

  const checkRole = (requiredRole) => {
    if (requiredRole) {
      return userRoles.includes(requiredRole)
    }
    return true
  }
  return (
    <React.Fragment>
      {Object.entries({...apps})
          .filter(([appName, {navigation}])=> !!navigation)
          .filter(([appName, {requireRole}]) => checkRole(requireRole))
          .sort((a, b) => {
            if (a[1].navigationSequence === b[1].navigationSequence) {
              return 0
            }
            return (a[1].navigationSequence > b[1].navigationSequence) ? 1 : -1
          })
          .map(([appName, {label, navRoute}]) => {

        return (
          <NavLink
            key={appName}
            variant="link"
            to={navRoute}
          >
            {label}
          </NavLink>
        )

      })}
    </React.Fragment>
  )
}

export default BaseNav
