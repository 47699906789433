import React from 'react'
import Markdown from 'react-markdown'

import HelpContext from './HelpContext'


const HelpLink = (props) => {
  const {onOpen} = React.useContext(HelpContext)
  const {href} = props
    const handleSlugClick = slug => (e) => {
      e.preventDefault()
      e.stopPropagation()
      onOpen(slug)
    }

  return (
    <a href="/" onClick={handleSlugClick(href.split("|")[1])}>{props.node.children[0].value}</a>
  )
}

const customerRenderers = {
  link: (props) => {

    const {href} = props

    if ( href.indexOf('slug|') === 0 ) {
      return <HelpLink {...props} />
    } else {
      return (
        <a {...props}>{props.node.children[0].value}</a>
      )
    }

  }
}

const MarkdownRenderer = (props) => {
  return (
    <Markdown
      renderers={customerRenderers}
      {...props}
    />
  )
}

export default MarkdownRenderer
