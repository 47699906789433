import React from 'react'
import clsx from 'clsx'
import color from 'color'
import { useTheme, createUseStyles } from 'react-jss'
import AsyncSelect from 'react-select/async'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown as DownIcon } from '@fortawesome/free-solid-svg-icons'

import ActionDropdown from './ActionDropdown'
import Flex from './Flex'


const searchSelectStyleOverrides = {
  control: (provided, state) => ({
    ...provided,
    color: state.selectProps.hoodooTheme.colors.default.color,
    backgroundColor: color(state.selectProps.hoodooTheme.colors.primary.color).alpha(.1).rgb().string(),
    borderWidth: "1px",
    borderColor: "transparent",
    borderRadius: "2px",
    minHeight: '0px',
    '&:hover': {
      borderColor: state.selectProps.hoodooTheme.colors.primary.color,
      boxShadow: "none",
    }
  }),
  menu: (provided, state) => ({
    ...provided,
    color: state.selectProps.hoodooTheme.colors.default.color,
    backgroundColor: state.selectProps.hoodooTheme.colors.default.backgroundColor,
    zIndex: 1000,
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    padding: state.selectProps.hoodooTheme.space.xxs,
  }),
  input: (provided, state) => ({
    ...provided,
    margin: "0px",
    padding: "0px",
  }),
  //indicatorSeparator: (provided, state) => ({
  //...provided,
  //marginTop: "0px",
  //marginBottom: "0px",
  //}),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    padding: "0px",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    padding: "0px",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    textOverflow: 'unset',
    whiteSpace: "break-spaces",
    color: state.selectProps.hoodooTheme.colors.default.color,
    backgroundColor: "none",
  }),
}

const useStyles = createUseStyles(theme => ({
  // base styles
  inputControl: {
    marginBottom: theme.space.sm,

    // all inputs
    '& input, & textarea, & select': {
      width: "100%",
      boxSizing: "border-box",
      borderColor: "transparent",
      borderStyle: "solid",
      borderWidth: "1px",
      borderRadius: "2px",
      paddingTop: theme.space.xxs,
      paddingBottom: theme.space.xxs,
      paddingLeft: theme.space.xxs,
      paddingRight: theme.space.xxs,
      '&:focus, &:hover': {
        backgroundColor: color(theme.colors.primary.color).alpha(.1).rgb().string(),
      },
    },

    // special handling for default checkbox
    '& .checkbox-helper': {
      display: "flex",
      flexDirection: "row",
      alignItems: "stretch",
    },
    '& input[type=checkbox]': {
      width: theme.space.lg,
      height: theme.space.lg,
      margin: "0px",
      display: "inline-block",
      '& ~.input-label' : {
        paddingLeft: theme.space.sm,
        fontSize: "inherit",
        lineHeight: theme.space.lg,
        display: "inline-block",
        fontStyle: "normal",
        opacity: 1,
      },
    },

    // textarea
    '& textarea': {
      minHeight: theme.size.xl,
      resize: "vertical",
      color: theme.colors.default.color,
      //backgroundColor: theme.colors.default.backgroundColor,
    },

    // input labels - this class must be set on the input label component
    '& .input-label': {
      fontSize: theme.size.sm,
      fontStyle: "oblique",
      opacity: .5,
    },

    // input description - this class must be set on the input description component
    '& .input-description': {
      fontSize: theme.size.sm,
      fontStyle: "oblique",
      opacity: .5,
    },

    // input error messages - this class must be set on the input errors component
    '& .input-error': {
      fontSize: theme.size.sm,
      fontStyle: "oblique",
      color: theme.colors.danger.color,
    },

    '&:focus-within, &:hover': {
      '& .input-label': {
        opacity: 1,
      },
    },

    '&.error .input-label': {
      color: theme.colors.danger.color,
    },

    '&.error input, &.error textarea': {
      backgroundColor: color(theme.colors.danger.color).alpha(.1).rgb().string(),
      '&:focus, &:hover': {
        backgroundColor: color(theme.colors.danger.color).alpha(.18).rgb().string(),
      }
    },
    '& *:disabled': {
      borderColor: "transparent",
    },
    '& *:disabled:hover, & *:disabled:focus': {
      borderColor: "transparent",
      backgroundColor: "lightgray",
      cursor: "not-allowed",
    },
  },

  // helpers
  fullWidth: {
    width: "100%",
  },
  inline: {
    display: "inline-block",
  },
  hidden: {
    // this should be enough to take the element out of the flow
    position: 'absolute',
  },

  // variant styles
  filled: {
    // all inputs
    '& input, & textarea, & select': {
      borderColor: "transparent",
      backgroundColor: color(theme.colors.primary.color).alpha(.1).rgb().string(),
      '& option:checked': {
        backgroundColor: color(theme.colors.primary.color).alpha(.1).rgb().string(),
      },
      '&:focus, &:hover': {
        borderColor: theme.colors.primary.color,
      }
    },
    '&.error input, &.error textarea, &.error select': {
      borderColor: "transparent",
      backgroundColor: color(theme.colors.danger.color).alpha(.18).rgb().string(),
      '&:focus, &:hover': {
        borderColor: theme.colors.danger.color,
      }
    },
  },
}))

// LAYOUT COMPONENTS

export const InputControl = (props) => {
  const classes = useStyles()

  const {
    children,
    hasError=false,
    variant="filled",
    inline=false,
    fullWidth=true,
    hidden=false,
  } = props

  return (
    <div className={clsx(
      'input-control',
      classes.inputControl,
      variant === 'outlined' && classes.outlined,
      variant === 'filled' && classes.filled,
      fullWidth && classes.fullWidth,
      inline && classes.inline,
      hidden && classes.hidden,
      hasError && 'error',
    )}>
      {children}
    </div>
  )
}

export const InputLabel = (props) => {
  const classes = useStyles()

  const {
    children,
  } = props

  return (
    <div className={clsx(classes.inputLabel, 'input-label')}>
      {children}
    </div>
  )
}

export const InputErrors = (props) => {
  const {
    errors=[],
  } = props

  if (errors.length < 1) {
    return null
  }

  return (
    <div className="input-error">
      {errors.map((message, id) => {
        return (
          <div key={id}>
            {message}
          </div>
        )
      })}
    </div>
  )
}

export const InputDescription = (props) => {
  const {
    children,
  } = props

  return (
    <div className="input-description">
      {children}
    </div>
  )
}

// INPUT RENDERING COMPONENTS

export const BooleanSelectInput = React.forwardRef((props, ref) => {
  const {
    trueLabel = 'Yes',
    falseLabel = 'No',
    trueValue = true,
    falseValue = false,
    ...inputProps
  } = props

  return (
    <select ref={ref} {...inputProps}>
      <option value={trueValue}>{trueLabel}</option>
      <option value={falseValue}>{falseLabel}</option>
    </select>
  )
})

export const CheckboxInput = React.forwardRef((props, ref) => {
  const {
    children,
    ...inputProps
  } = props


  return (
    <input
      type="checkbox"
      ref={ref}
      {...inputProps}
    />
  )
})

export const DecimalInput = React.forwardRef((props, ref) => {
  return (
    <TextInput
      ref={ref}
      style={{textAlign: "right"}}
      type="number"
      step="any"
      {...props}
    />
  )
})

export const HiddenInput = React.forwardRef((props, ref) => {
  const { children, ...inputProps } = props

  return (<input type="hidden" ref={ref} {...inputProps} />)
})

export const SearchSelectInput = (props) => {
  const {
    disabled,
    ...inputProps
  } = props

  const theme = useTheme()

  return (
    <AsyncSelect
      hoodooTheme={theme}
      styles={searchSelectStyleOverrides}
      {...{isDisabled: disabled, ...inputProps}}
    />
  )
}

export const SelectInput = React.forwardRef((props, ref) => {
  const {
    options=[],
    ...inputProps
  } = props

  return (
    <select ref={ref} {...inputProps}>
      {options.map(({value, label}, id) => (
        <option key={value} value={value}>{label}</option>
      ))}
    </select>
  )
})

export const TextInput = React.forwardRef((props, ref) => {
  const {
    children,
    ...inputProps
  } = props

  return (
    <input ref={ref} type="text" {...inputProps} />
  )
})

export const DateInput = React.forwardRef((props, ref) => {
  const {
    children,
    ...inputProps
  } = props

  return (
    <input ref={ref} type="date" {...inputProps} />
  )
})

export const DateTimeInput = React.forwardRef((props, ref) => {
  const {
    children,
    ...inputProps
  } = props

  return (
    <input ref={ref} type="datetime-local" {...inputProps} />
  )
})

export const TimeInput = React.forwardRef((props, ref) => {
  const {
    children,
    ...inputProps
  } = props

  return (
    <input ref={ref} type="time" {...inputProps} />
  )
})

export const TextareaInput = React.forwardRef((props, ref) => {
  const {
    children,
    ...inputProps
  } = props

  const handleAutoSize = e => {
    let el = e.target
    // extra 2px is a hack to account for the padding, I think....
    el.style.height = (el.scrollHeight+2)+"px"
  }

  return (
    <textarea
      onInput={handleAutoSize}
      onFocus={handleAutoSize}
      // TODO: Textarea: add func to shrink to initial on blur evt
      //onBlur={handleAutoSize}
      ref={ref}
      {...inputProps}
    />
  )
})

// MORE COMPLEX INPUTS

export const SingleInputConversionInput = React.forwardRef((props, ref) => {

  const {
    allowedUnits = ['grams', 'kilograms', 'ounces', 'pounds'],
    unitDefinitions = {},
    defaultUnit,
    defaultValue,
    onChange,
  } = props

  const validatedDefaultUnit = (defaultUnit && allowedUnits.includes(defaultUnit) ? defaultUnit : 'grams')

  const [unit, setUnit] = React.useState(unitDefinitions[validatedDefaultUnit])

  const validatedDefaultValue = (defaultValue && defaultValue !== '')
    ? defaultValue
    : 0.00

  const [value, setValue] = React.useState(validatedDefaultValue)

  const unitActions = allowedUnits.map(unitName => ({
    label: unitDefinitions[unitName].optionLabel,
    action: () => setUnit(unitDefinitions[unitName])
  }))

  const handleChange = e => {
    setValue(unit.persistenceValueFromInputValue(e.target.value || 0))
  }

  React.useEffect(() => {
    if (onChange) {
      onChange(value)
    }
  }, [value, onChange])

  return (

    <Flex container flexDirection="row" alignItems="center">
      <Flex flexGrow="1">
        <DecimalInput
          step={0.01}
          ref={ref}
          min={0}
          onChange={handleChange}
          value={unit.inputValueFromPersistenceValue(value)}
        />
      </Flex>
      <Flex flexGrow="0" paddingLeft="4px">
        <ActionDropdown actions={unitActions}>
          {unit.unitLabel} <FontAwesomeIcon icon={DownIcon} />
        </ActionDropdown>
      </Flex>
    </Flex>

  )

})

// GENERIC CONTROL

export const inputMap = {
  BooleanSelectInput,
  CheckboxInput,
  DecimalInput,
  HiddenInput,
  SearchSelectInput,
  SelectInput,
  TextInput,
  DateInput,
  DateTimeInput,
  TimeInput,
  TextareaInput,
}

export const Input = React.forwardRef((props, ref) => {
  const {
    description,
    errors=[],
    hasError=false,
    label,
    type="TextInput",
    variant="filled",
    inputControlProps={},
    onChange=() => null, 
    inlineActions,
    ...inputProps
  } = props

  const InputRenderer = (typeof type === 'string' ? inputMap[type] : type) || TextInput

  // special rendering for checkbox
  if ( InputRenderer === CheckboxInput ) return (
    <InputControl
      variant={variant}
      hasError={hasError || errors.length}
      hidden={type === 'HiddenInput' ? true : false}
      {...inputControlProps}
    >
      <Flex container flexDirection="row">

        <Flex flexGrow="0">
          <InputRenderer
            ref={ref}
            onChange={onChange} // ensure an onchange exists with the default
            {...inputProps}
          />
        </Flex>

        <Flex flexGrow="1" paddingLeft="8px">
          {label && label}
        </Flex>

        {inlineActions && (
          <Flex flexGrow="0">
            {inlineActions}
          </Flex>
        )}

        <Flex container flexDirection="row">
          <Flex flexGrow="1">

            {description && (<InputDescription>{description}</InputDescription>)}

            {errors && (<InputErrors errors={errors} />)}

          </Flex>
        </Flex>
      </Flex>

    </InputControl>
  )

  return (
    <InputControl
      variant={variant}
      hasError={hasError || errors.length}
      hidden={type === 'HiddenInput' ? true : false}
      {...inputControlProps}
    >
      {label && (<InputLabel>{label}</InputLabel>)}

      <Flex container flexDirection="row">
        <Flex flexGrow="1">

          <InputRenderer
            ref={ref}
            onChange={onChange} // ensure an onchange exists with the default
            {...inputProps}
          />

        </Flex>
        {inlineActions && (
          <Flex flexGrow="0">
            {inlineActions}
          </Flex>
        )}
      </Flex>

      {description && (<InputDescription>{description}</InputDescription>)}

      {errors && (<InputErrors errors={errors} />)}
    </InputControl>
  )
})
