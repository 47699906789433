import React from 'react'
import clsx from 'clsx'


const TableData = (props) => {

  const {
    children,
    align,
    wrap,
    shrink = false,
    danger,
    success,
    warning,
    clickable,
    ...elementProps
  } = props

  return (

    <td
      className={clsx(
        shrink && 'shrinkCell',
        align && align,
        wrap && 'wrap',
        danger && 'danger',
        success && 'success',
        warning && 'warning',
        clickable && 'clickable',
      )}
      {...elementProps}
    >
      {children}
    </td>

  )

}

export default TableData
