import React from 'react'
import { merge } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSort as RowSortIcon } from '@fortawesome/free-solid-svg-icons'

//import { maybeFunc } from '../util'
import Checkbox from '../components/Checkbox'
import Table from '../components/TableNg'
import TableHead from '../components/TableHead'
import TableBody from '../components/TableBody'
import TableRow from '../components/TableRow'
import TableHeading from '../components/TableHeading'
import TableData from '../components/TableData'


const RowSelectHeading = (props) => {

  return (
    <TableHeading>
      <Checkbox />
    </TableHeading>
  )
}

const RowSelectCell = (props) => {

  return (
    <TableData>
      <Checkbox />
    </TableData>
  )
}

const RowSortHeading = (props) => {

  return (
    <TableHeading>
    </TableHeading>
  )
}

const RowSortCell = (props) => {

  return (
    <TableData>
      <FontAwesomeIcon icon={RowSortIcon} />
    </TableData>
  )
}

const TableHeadings = (props) => {
  const { columns, selectable, sortable } = props
  return (
    <TableRow>
      {sortable && (
        <RowSortHeading />
      )}
      {selectable && (
        <RowSelectHeading />
      )}
      {columns.map((col) => {
        return (
          <TableHeading key={col.name}>
            {col.label}
          </TableHeading>
        )
      })}
    </TableRow>
  )
}

const TableRows = (props) => {
  const { columns, data, selectable, sortable, valueFromRow } = props
  return (
    <>
      {data.map((row, rowIndex) => {
        return (
          <TableRow key={rowIndex}>
            {sortable && (
              <RowSortCell />
            )}
            {selectable && (
              <RowSelectCell />
            )}
            {columns.map((col, colIndex) => {
              return (
                <TableData key={col.name}>
                  {valueFromRow({row, rowIndex, column: col, columnIndex: col.columnIndex})}
                </TableData>
              )
            })}
          </TableRow>
        )
      })}
    </>
  )
}

export const MagicTableBody = (props) => {

  const {
    children,
  } = props

  return (
    <TableBody>
      {children}
    </TableBody>
  )
}

const columnIndexValue = ({row, columnIndex}) => {
  return row[columnIndex]
}

const columnKeyValue = ({row, column: {name}}) => {
  return row[name]
}

const tableDefaults = {
  showHeadings: true,
  selectable: false,
  sortable: false,
  tableProps: {tableStyle: "dataTable", fullWidth: true},
  emptyTableMessage: "Nothing to see here...",
  RenderHeadings: TableHeadings,
  headingsProps: {},
  RenderRows: TableRows,
  rowsProps: {},
}

const indexTableDefaults = {
  ...tableDefaults,
  rowsProps: {
    valueFromRow: columnIndexValue,
  },
}

const keyedTableDefaults = {
  ...tableDefaults,
  rowsProps: {
    valueFromRow: columnKeyValue,
  },
}

const columnDefaults = {
  name: undefined,
  label: '',
  visible: true,
  headingProps: {},
  dataProps: {},
}

/**
 * generate column config object with colName as keys, merging default values
 */
const prepareColumnConfig = (colConfig = {}) => {
  return Object.entries(colConfig).reduce((acc, [colName, config], colIndex) => {
    acc[colName] = merge({columnIndex: colIndex, name: colName}, columnDefaults, config)
    return acc
  }, {})
}

/**
 * prepare column configuration and various state management requirements
 * for table display and control
 *
 * TODO: useTable features:
 *  [*] basic column config / headings renderer
 *  [*] basic index based data renderer
 *  [*] basic object key based data renderer
 *  [ ] data sort order index / re-order methdos
 *  [ ] data multi select render + logic
 *  [ ] column visibility render + logic
 *  [ ] per column filters 
 *    [ ] external (change api call)
 *    [ ] internal (filter list in js) )
 *
 */
export const useTable = (config, data) => {

  // TODO: memoize for optimization
  const { columns, ExpandRow, columnValue='index', ...tableConfigOpts } = config

  const defaultTableConfig = (() => {
    switch(columnValue) {
      case 'index':
        return indexTableDefaults
      case 'key':
      default:
        return keyedTableDefaults
    }
  })()

  const tableConfig = merge({}, defaultTableConfig, tableConfigOpts)

  const columnConfig = prepareColumnConfig(config.columns)

  const columnState = (() => {

    if (tableConfig.showColumns) {
      return Object.entries(columnConfig).reduce((acc, [colName, col]) => {
        if (tableConfig.showColumns.includes(colName)) {
          acc[colName] = col
        }
        return acc
      }, {})
    }

    if (tableConfig.excludeColumns) {
      return Object.entries(columnConfig).reduce((acc, [colName, col]) => {
        if (!tableConfig.excludeColumns.includes(colName)) {
          acc[colName] = col
        }
        return acc
      }, {})
    }

    return columnConfig

  })()
  //const [columnState, setColumnState] = React.useState(columnConfig)

  const renderColumns = Object.values(columnState).filter(i => i.visible)

  return {
    tableConfig,
    ExpandRow,
    columnState,
    columns: renderColumns,
    data,
  }
}

export const MagicTableHead = ({table}) => {

  const {
    tableConfig: {
      RenderHeadings,
      headingsProps,
      selectable,
      sortable,
    },
    columns
  } = table


  return (

    <TableHead>
      <RenderHeadings
        sortable={sortable}
        selectable={selectable}
        columns={columns}
        {...headingsProps}
      />
    </TableHead>
  )
}

const MagicTable = (props) => {

  const {config, data} = props

  const table = useTable(config, data)

  const {
    tableConfig: {
      tableProps={},
      RenderHeadings,
      headingsProps,
      RenderRows,
      rowsProps,
      selectable,
      sortable,
    },
    columns
  } = table

  return (

    <Table {...tableProps}>
      <TableHead>
        <RenderHeadings
          sortable={sortable}
          selectable={selectable}
          columns={columns}
          {...headingsProps}
        />
      </TableHead>
      <TableBody>
        <RenderRows
          sortable={sortable}
          selectable={selectable}
          columns={columns}
          data={data}
          {...rowsProps}
        />
      </TableBody>
    </Table>

  )

}

export default MagicTable
