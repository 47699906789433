import React from 'react'
import color from 'color'
import { ThemeProvider as RJTP, createUseStyles, useTheme } from 'react-jss'
import { merge } from 'lodash'

import { usePersistedState } from 'voodoo/hooks'
import { mediaStyles } from 'ui/layout/GlobalStyles'

import baseTheme from './themes/base'
import lightTheme from './themes/light'
import darkTheme from './themes/dark'
import ThemeCTX from './ThemeCTX'
import ToolBox from './components/ToolBox'


const useResetStyles = createUseStyles({
  '@global': {
    // BASE STYLES and RESET
    body: {
      padding: 0,
      margin: 0,
    },
  },
})

const useBaseStyles = createUseStyles(theme => ({
  '@global': {
    // BASE STYLES and RESET
    html: {
      overflow: 'hidden',
    },
    body: {
      backgroundColor: theme.backgroundColor,
      color: theme.colors.default.color,
      fontFamily: theme.fontFamily,
      fontSize: theme.size.md,
    },
    // inputs and buttons
    'input, textarea, select option': {
      fontFamily: theme.fontFamily,
      fontSize: theme.size.md,
      color: theme.colors.default.color,
      backgroundColor: theme.colors.default.offset,
      '&:focus': {
        outline: "none",
      },
    },
    select: {
      backgroundColor: theme.colors.default.offset,
      color: theme.colors.default.color,
      // TODO: add drop down arrow as removing appearance removes that too
      //'-moz-appearance': 'none',
      //'-webkit-appearance': 'none',
      fontFamily: theme.fontFamily,
      fontSize: theme.size.md,
      '&:focus': {
        outline: "none",
      },
    },
    textarea: {
      fontFamily: theme.fontFamily,
      fontSize: theme.size.md,
      '&:focus': {
        outline: "none",
      },
    },
    button: {
      fontFamily: theme.fontFamily,
      fontSize: theme.size.md,
      '&:focus': {
        outline: "none",
      },
    },
    '::placeholder': {
      color: theme.colors.disabled.color,
      //color: 'white',
      fontStyle: 'oblique',
    },
    '::selection': {
      color: theme.colors.primary.offset,
      backgroundColor: theme.colors.primary.color,
    },
    // scroll bar styles
    '::-webkit-scrollbar':{
      width: theme.size.xs,
      height: theme.size.xs,
    },
    '::-webkit-scrollbar-button':{
      display: "none",
    },
    '::-webkit-scrollbar-thumb':{
      backgroundColor: theme.colors.primary.color,
      borderRadius: theme.size.xs,
    },
    // GLOBAL CLASSES
    ...mediaStyles, // flex box responsive sizing
    '.hide': {  // hide
      display: 'none !important',
    },
    // BASIC HTML ELEMENTS
    'h1, h2, h3, h4, h5, h6': {
      marginTop: "0px",
      marginBottom: theme.space.sm,
      fontWeight: "normal",
    },
    'h1': {
      fontSize: theme.size.xxl,
    },
    'h2': {
      fontSize: theme.size.xl,
    },
    'h3': {
      fontSize: theme.size.lg,
    },
    'h4': {
      fontSize: theme.size.md,
    },
    'h5': {
      fontSize: theme.size.sm,
    },
    'h6': {
      fontSize: theme.size.xs,
    },
    'p': {
      marginTop: "0px",
      marginBottom: theme.space.sm,
    },
    // ripple effect kf for buttons
    '@keyframes ripple-effect': {
      "0%": {
        transform: 'scale(1)',
        opacity: 1,
      },
      '50%': {
        transform: 'scale(10)',
        opacity: 0.375,
      },
      '100%': {
        transform: 'scale(35)',
        opacity: 0,
      },
    }
  },
}))

const configuredThemeMap = {
  none: {},
  light: lightTheme,
  dark: darkTheme,
}

const configureSizing = (compactness, theme) => {
  const {sizeAndSpace, ...configuredTheme} = theme
  let sizeConfig = sizeAndSpace[compactness]
  return {
    ...configuredTheme,
    sizeAndSpace,
    size: {
      xxs: `calc(${sizeConfig.textBase -  7}px + ${sizeConfig.textResponsiveBase}vw)`,
      xs:  `calc(${sizeConfig.textBase -  5}px + ${sizeConfig.textResponsiveBase}vw)`,
      sm:  `calc(${sizeConfig.textBase -  2}px + ${sizeConfig.textResponsiveBase}vw)`,
      md:  `calc(${sizeConfig.textBase     }px + ${sizeConfig.textResponsiveBase}vw)`,
      lg:  `calc(${sizeConfig.textBase +  5}px + ${sizeConfig.textResponsiveBase}vw)`,
      xl:  `calc(${sizeConfig.textBase + 10}px + ${sizeConfig.textResponsiveBase}vw)`,
      xxl: `calc(${sizeConfig.textBase + 15}px + ${sizeConfig.textResponsiveBase}vw)`,
    },
    space: {
      xxs: `calc(${sizeConfig.spaceBase / 8}px + ${sizeConfig.spaceResponsiveBase}vw)`,
      xs:  `calc(${sizeConfig.spaceBase / 4}px + ${sizeConfig.spaceResponsiveBase}vw)`,
      sm:  `calc(${sizeConfig.spaceBase / 2}px + ${sizeConfig.spaceResponsiveBase}vw)`,
      md:  `calc(${sizeConfig.spaceBase    }px + ${sizeConfig.spaceResponsiveBase}vw)`,
      lg:  `calc(${sizeConfig.spaceBase * 2}px + ${sizeConfig.spaceResponsiveBase}vw)`,
      xl:  `calc(${sizeConfig.spaceBase * 4}px + ${sizeConfig.spaceResponsiveBase}vw)`,
      xxl: `calc(${sizeConfig.spaceBase * 8}px + ${sizeConfig.spaceResponsiveBase}vw)`,
    }
  }
}

/**
 * auto fill adjusted color keys, assumes default and offset set for all keys
 */
const configureColors = (theme) => {
  let keys = ['default', 'primary', 'secondary', 'success', 'warning', 'danger']

  // color categories
  // color - text color
  // backgroundColor - background color
  // offset - legacy, used like background color
  //

  // loop over theme colors, check for set adjustments, if not there, add keys
  keys.forEach(colorKey => {
    if (!theme.colors[colorKey].backgroundColor) {
      theme.colors[colorKey].backgroundColor = color( theme.colors[colorKey].color ).negate().hex()
    }
  })

  return theme
}

const createTheme = ({bases, compactness}) => {
  return configureColors(configureSizing(compactness, merge({}, ...bases)))
}

const InjectThemeStyles = ({children}) => {
  let theme = useTheme()
  useResetStyles()
  useBaseStyles({theme})
  return null
}

const ThemeProvider = ({children}) => {
  const [compactness, setCompactness] = usePersistedState('uiCompactness', 'normal')
  const [themeName, setThemeName] = usePersistedState('uiThemeName', 'light')
  const [userTheme, setUserTheme] = usePersistedState('uiUserThemeOverrides', {})
  //const [userTheme, setUserTheme] = React.useState({})



  const activeTheme = createTheme({
    bases: [baseTheme, configuredThemeMap[themeName], userTheme],
    compactness,
  })

  const ctx = {
    compactness,
    setCompactness,
    setThemeName,
    setUserTheme,
    themeName,
    userTheme,
  }
  return (
    <RJTP theme={activeTheme}>
      <ThemeCTX.Provider value={ctx}>
        <InjectThemeStyles />
        <ToolBox />
        {children}
      </ThemeCTX.Provider>
    </RJTP>
  )
}

export default ThemeProvider
