import React from 'react'
import clsx from 'clsx'
import { createUseStyles } from 'react-jss'


const useStyles = createUseStyles({
  root: props => ({
    display: (props.container) ? 'flex' : undefined,
    flexGrow: (props.container) ? 1: undefined,
    flexWrap: (props.container) ? 'wrap' : undefined,
    flexDirection: (props.container) ? 'row' : undefined,
    boxSizing: 'border-box',
    ...props,
  }),
})

const Flex = ({
  children,
  xs, sm, md, lg, xl,
  show, hide, className,
  ...styleProps
}) => {

  const styleClasses = useStyles(styleProps)

  const widthClasses = Object.entries({xs, sm, md, lg, xl}).reduce((widths, [name, size]) => {
    if (size) {
      widths.push(`flex-${name}-up-${size}`)
    }
    return widths
  }, [])

  const combinedClasses = clsx(
    styleClasses.root,
    widthClasses,
    (styleProps.container) ? 'flexContainer' : 'flexItem',
    (show === false) ? 'hide' : ((show && show !== true) && `hide-${show}-down`),
    (hide === true) ? 'hide' : ((hide && hide !== false) && `hide-${hide}-up`),
    className,
  )

  return (
    <div className={combinedClasses}>
      {children}
    </div>
  )
}

export default Flex
