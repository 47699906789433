import React from 'react'

import BlockErrorBoundary from './BlockErrorBoundary'


/**
 * the block renderer
 *
 * if provieded with Component, Component will be responsible for further
 * rendering. 
 *
 * if not, but provided with content[], each item of content will be 
 * renderered in new BlockRenderer components, props being passed along
 */
const BlockRenderer = (props) => {

  const {
    Component,
    content=[],
    ...passThroughProps
  } = props

  if (Component) return (
    <BlockErrorBoundary>
      <Component content={content} {...passThroughProps} />
    </BlockErrorBoundary>
  )

  if (content.length) return (
    <React.Fragment>
      {content.map((c,id) => (
        <BlockErrorBoundary key={id} >
          <BlockRenderer {...c} />
        </BlockErrorBoundary>
      ))}
    </React.Fragment>
  )

  return null
}

export default BlockRenderer
