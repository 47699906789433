import { useLocation } from 'react-router-dom'


const useSearchParams = (defaults={}) => {

  return Array.from(new URLSearchParams(useLocation().search).entries())
    .reduce((acc, [key, value]) => {
      acc[key] = value
      return acc
    }, defaults)

}

export default useSearchParams
