import Context from './Context'
import DefaultFallback from './DefaultFallback'
import useWitchy from './useWitchy'


const ContextContainer = (props) => {

  const {
    children,
    Fallback = DefaultFallback,
    prepared = true,
    ...injectContext
  } = props

  const {context, ...ctx} = useWitchy()

  const newContext = {...context, ...injectContext}

  if ( !prepared ) return (<Fallback />)

  return (

    <Context.Provider value={{...ctx, context:newContext}}>

      {children}

    </Context.Provider>

  )

}

export default ContextContainer
