import React from 'react'


const Input = (props, ref) => {
  const {
    children,
    ...inputProps
  } = props

  return (
    <input ref={ref} {...inputProps} />
  )
}

export default React.forwardRef(Input)
