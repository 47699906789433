
import { Switch, Route } from "react-router-dom"

import { useBlockContext } from '../hooks'
import BlockRenderer from '../BlockRenderer'

import ViewContextBlock from './ViewContextBlock'


/**
 * render content or Component inside a <Switch>, expects a route object and provides route params, url
 */
const SwitchRouterBlock = (props) => {
  const {
    content=[],
  } = props

  const {routes} = useBlockContext()

  return (
    <Switch>
      {content.map((block, id) => {
        const { path, exact } = routes[block.route]

        return (
          <Route key={block.route} path={path} exact={exact}>
            <BlockRenderer Component={ViewContextBlock} {...block} />
          </Route>
        )
      })}
    </Switch>
  )
}

export default SwitchRouterBlock
