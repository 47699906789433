import React from 'react'

const hoodooConfig = {
  apps: {
    catalog: {
      AppComponent: React.lazy(() => import('./App.js')),
      basePath: "/catalog",
      label: "Catalog",
      navRoute: 'catalog.products.list',
      navigation: true,
      navigationSequence: 100,
      routes: {
        dashboard: {
          path: "/",
          exact: true,
        },
        products: {
          path: "/products",
          exact: false,
          virtual: false,
          routes: {
            list: {
              path: "",
              exact: true,
            },
            create: {
              path: '/create',
              exact: true,
            },
            read: {
              path: '/:productId',
              exact: true,
            },
            duplicate: {
              path: '/:productId/duplicate',
              exact: true,
            },
            update: {
              path: '/:productId/edit',
              exact: true,
            },
          },
        },
        products2: {
          path: "/products2",
          exact: false,
          virtual: false,
          routes: {
            list: {
              path: "",
              exact: true,
            },
            create: {
              path: '/create',
              exact: true,
            },
            read: {
              path: '/:productId',
              exact: true,
            },
            update: {
              path: '/:productId/edit',
              exact: true,
            },
          },
        },
        productDepartments: {
          path: "/productDepartments",
          exact: false,
          virtual: false,
          routes: {
            list: {
              path: "",
              exact: true,
            },
            create: {
              path: '/create',
              exact: true,
            },
            read: {
              path: '/:productDepartmentId',
              exact: true,
            },
            update: {
              path: '/productDepartmentId/edit',
              exact: true,
            },
          },
        },
        priceClasses: {
          path: "/priceClasses",
          exact: false,
          virtual: false,
          routes: {
            list: {
              path: "",
              exact: true,
            },
            create: {
              path: '/create',
              exact: true,
            },
            read: {
              path: '/:priceClassId',
              exact: true,
            },
            update: {
              path: '/:priceClassId/edit',
              exact: true,
            },
          },
        },
        pricing: {
          path: "/pricing",
          exact: false,
          virtual: false,
          routes: {
            list: {
              path: "",
              exact: true,
            }
          }
        },
      },
    },
  },
}

export default hoodooConfig
