import React from 'react'

import BlockRenderer from '../BlockRenderer'
import { useBlockContext, useFilterList } from '../hooks'
import BlockContext from '../BlockContext'


/**
 * an async context block rendering and providing useFilterList reducer
 */
const AsyncFilterListContextBlock = (props) => {

  const {
    getData=() => [],
    filters={},
    initialData=[],
    pageSize,
    page,
    contextKey='filterList',
    enableMultiSelect=false,
    multiSelectActions,
    ...passThroughProps
  } = props

  const blockCtx = useBlockContext()

  const filterListContext = useFilterList({
    getData,
    filters,
    initialData,
    filterValues: Object.entries(filters).reduce((acc, [key, filter]) => {
      acc[key] = filter.defaultValue || ''
      return acc
    }, {}),
    page,
    pageSize,
    enableMultiSelect,
    multiSelectActions,
  })

  const newCtx = {...blockCtx, [contextKey]: filterListContext}

  return (
    <BlockContext.Provider value={newCtx}>
      <BlockRenderer {...passThroughProps} />
    </BlockContext.Provider>
  )
}

export default AsyncFilterListContextBlock
