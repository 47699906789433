import React from 'react'


const AppLoader = ({appName, appConfig}) => {
  const { AppComponent } = appConfig
 
  return (
    <React.Suspense fallback={<div>Loading Sub-Application</div>}>
      <AppComponent appName={appName} appConfig={appConfig} />
    </React.Suspense>
  )
}

export default AppLoader
