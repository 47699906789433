import React from 'react'
import { createUseStyles } from 'react-jss'

import config from 'config'

import { voodooLog } from '../util'


const useStyles = createUseStyles(theme => ({
  connectionErrorCurtain: {
    position: "fixed",
    top: "0px",
    bottom: "0px",
    left: "0px",
    right: "0px",
    zIndex: 1000,
    backgroundColor: theme.colors.primary.color,
    opacity: .5,
  },

  connectionErrorContentContainer: {
    position: "fixed",
    top: "0px",
    bottom: "0px",
    left: "0px",
    right: "0px",
    zIndex: 1001,
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "center",
    alignItems: "center",
  },

  connectionErrorContent: {
    backgroundColor: theme.colors.default.backgroundColor,
    padding: theme.space.lg,
    borderRadius: theme.space.xxs,
    borderColor: theme.colors.danger.color,
    borderWidth: "4px",
    borderStyle: "solid",
    textAlign: "center",
  }

}))

const serverBaseURL = `${config.API_PROTOCOL}://${config.API_HOST}${(config.API_PORT)?":"+config.API_PORT:''}${config.API_BASEPATH}`

const checkServerUp = async () => {

  let response = await fetch(serverBaseURL + "/up", { method: 'GET' })

  let data = await response.json()

  return data


}

const ServerCheck = (props) => {

  const intervalRef = React.useRef(null)

  const recheckIntervalRef = React.useRef(1000)

  const [hasTrouble, setHasTrouble] = React.useState(false)

  const classes = useStyles()

  const doRecheck = async () => {

    let currentTimeout = recheckIntervalRef.current || 1000
    let nextTimeout = (currentTimeout < 1000 * 60)
      ? currentTimeout * 2
      : currentTimeout

    try {

      voodooLog("Checking for server availability...")
      let response = await checkServerUp()

      if ( response && response.serverStatus === "up" ) {

        voodooLog("... server connection restored")

        recheckIntervalRef.current = 1000 // reset check timing

        window.toast.success("Re-established server connection...")
        setHasTrouble(false) // clear trouble

        return

      } else {

        throw new Error("Server check retruned, but not as expected")
      }

    } catch {

      voodooLog(`...still cannot determine server status, rechecking in ${nextTimeout / 1000} seconds...`)

    }

    recheckIntervalRef.current = nextTimeout


    setTimeout(doRecheck, nextTimeout)
  }

  const checkServer = async () => {

    let serverUp = false

    try {

      let response = await checkServerUp()

      serverUp = response.serverStatus === 'up'

    } catch { 

      //

    }

    // set warning if server up didn't check out
    if ( !serverUp  && !hasTrouble ) {

      setHasTrouble(true)

      doRecheck()
    }

    // clear warning if the check happens to find the server in good shape
    if ( serverUp && hasTrouble ) setHasTrouble(false)
  }

  React.useEffect(() => {

    if ( hasTrouble === false ) {

      // if no trouble, set the check interval

      intervalRef.current = setInterval(
        checkServer,
        config.SERVER_CHECK_INTERVAL)

    } else if ( intervalRef.current ) {

      // if we have already detected trouble, stop the regular check interval

      clearInterval(intervalRef.current)

    }

    // stop checking when unloaded if present

    return () => (intervalRef.current)
      ? clearInterval(intervalRef.current)
      : null

  }, [hasTrouble]) // eslint-disable-line

  if ( !hasTrouble ) return null

  return (

    <React.Fragment>

      <div className={classes.connectionErrorCurtain} onClick={e => {
        // avoid click through to UI, chance to save UI state while reconnecting
        e.stopPropagation(); e.preventDefault();
      }}/>

      <div className={classes.connectionErrorContentContainer}>
        <div className={classes.connectionErrorContent}>
          Cannot connect to the Voodoo server.
          <br /> <br />
          Trying to re-establish connection....
        </div>
      </div>

    </React.Fragment>

  )

}

export default ServerCheck
