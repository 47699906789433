import React from 'react'
import clsx from 'clsx'
import color from 'color'
import { createUseStyles } from 'react-jss'


const useStyles = createUseStyles(theme => ({
  inputControl: {
    marginBottom: theme.space.sm,

    // all inputs
    '& input, & textarea, & select': {
      width: "100%",
      boxSizing: "border-box",
      borderColor: "transparent",
      borderStyle: "solid",
      borderWidth: "1px",
      borderRadius: "2px",
      paddingTop: theme.space.xxs,
      paddingBottom: theme.space.xxs,
      paddingLeft: theme.space.xxs,
      paddingRight: theme.space.xxs,
      '&:focus, &:hover': {
        backgroundColor: color(theme.colors.primary.color).alpha(.1).rgb().string(),
      },
    },

    // special handling for default checkbox
    '& .checkbox-helper': {
      display: "flex",
      flexDirection: "row",
      alignItems: "stretch",
    },
    '& input[type=checkbox]': {
      width: theme.space.lg,
      height: theme.space.lg,
      margin: "0px",
      display: "inline-block",
      '& ~.input-label' : {
        paddingLeft: theme.space.sm,
        fontSize: "inherit",
        lineHeight: theme.space.lg,
        display: "inline-block",
        fontStyle: "normal",
        opacity: 1,
      },
    },

    // textarea
    '& textarea': {
      minHeight: theme.size.xl,
      resize: "vertical",
      color: theme.colors.default.color,
      //backgroundColor: theme.colors.default.backgroundColor,
    },

    // input labels - this class must be set on the input label component
    '& .input-label': {
      fontSize: theme.size.sm,
      fontStyle: "oblique",
      opacity: .5,
    },

    // input error messages - this class must be set on the input errors component
    '& .input-error': {
      fontSize: theme.size.sm,
      fontStyle: "oblique",
      color: theme.colors.danger.color,
    },

    '&:focus-within, &:hover': {
      '& .input-label': {
        opacity: 1,
      },
    },

    '&.error .input-label': {
      color: theme.colors.danger.color,
    },

    '&.error input, &.error textarea': {
      backgroundColor: color(theme.colors.danger.color).alpha(.1).rgb().string(),
      '&:focus, &:hover': {
        backgroundColor: color(theme.colors.danger.color).alpha(.18).rgb().string(),
      }
    },
    '& *:disabled': {
      borderColor: "transparent",
    },
    '& *:disabled:hover, & *:disabled:focus': {
      borderColor: "transparent",
      backgroundColor: "lightgray",
      cursor: "not-allowed",
    },
  },

  outlined: {
    // all inputs
    '& input, & textarea, & select': {
      borderColor: color(theme.colors.default.color).alpha(.5).rgb().string(),
      '&:focus, &:hover': {
        backgroundColor: color(theme.colors.primary.color).alpha(.1).rgb().string(),
        borderColor: theme.colors.primary.color,
      }
    },
    '&.error input, &.error textarea, &.error select': {
      borderColor: color(theme.colors.danger.color).alpha(.18).rgb().string(),
      '&:focus, &:hover': {
        borderColor: theme.colors.danger.color,
      }
    },
  },
  fullWidth: {
    width: "100%",
  },
  inline: {
    display: "inline-block",
  },
}))

const InputControl = (props) => {
  const classes = useStyles()

  const {
    children,
    hasError=false,
    variant,
    inline=false,
    fullWidth=true,
  } = props

  return (
    <div className={clsx(
      classes.inputControl,
      variant === 'outlined' && classes.outlined,
      fullWidth && classes.fullWidth,
      inline && classes.inline,
      hasError && 'error',
    )}>
      {children}
    </div>
  )
}

export default InputControl
