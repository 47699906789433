import React, { useContext, useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

import Flex from './components/Flex';


export const ToasterCTX = React.createContext();

const useToastStyles = createUseStyles(theme => ({
  root: {
    backgroundColor: props => theme.colors[props.color].color,
    color: props => theme.colors[props.color].offset,
    borderRadius: theme.baseRadius,
    padding: theme.space.sm,
    display: "flex",
  },
  label: {
    flexGrow: 0,
    paddingRight: theme.space.sm,
    fontWeight: 'bold',
  },
  message: {
    flexGrow: 1,
  },
  actions: {
    flexGrow: 0,
    paddingleft: theme.space.sm,
    fontWeight: 'bold',
  }
}));

// TODO: investigate toasts hiding one another, or clearing the previous
// TODO: restyle toasts with new ui/theme
export const Toast = ({id, color="primary", timeout=5000, label, message, dismissable=false, ...rootProps}) => {
  const classes = useToastStyles({color});
  //const {imDone} = useContext(ToasterCTX);

  //useEffect(() => {
    //let popTimeout = setTimeout(() => imDone(id), timeout);
    //return () => clearTimeout(popTimeout);
  //}, [id, imDone, timeout]);

  return (
    <div className={classes.root}>
      {label && (
        <div className={classes.label}>{label}</div>
      )}
      <div className={classes.message}>{message}</div>
      {dismissable && (
        <div className={classes.actions}>
          <FontAwesomeIcon icon={faTimes} />
        </div>
      )}
    </div>
  );
};

const useStyles = createUseStyles(theme => ({
  root:{
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
  },
  toast: {
    paddingLeft: theme.space.sm,
    paddingRight: theme.space.sm,
    paddingBottom: theme.space.sm,
  }
}));

const ToastCaddy = ({toast}) => {
  const {imDone} = useContext(ToasterCTX);
  const classes = useStyles();

  useEffect(() => {
    let timeout = setTimeout(() => imDone(toast.timestamp), toast.timeout)
    return () => clearTimeout(timeout);
  });

  return (
    <div className={classes.toast}>
      <Toast {...toast} />
    </div>
  );
};

const ToastRenderer = () => {
  const {queue} = useContext(ToasterCTX);
  const classes = useStyles();

  if (queue.length < 1) return null;
  return (
    <div className={classes.root}>
      <Flex container flexDirection="row" justifyContent="flex-end">
        <Flex xs={12} sm={8} md={6} lg={4}>
          {queue.map((t) => (
            <ToastCaddy key={t.timestamp} toast={t} />
          ))}
        </Flex>
      </Flex>
    </div>
  );
};

const Toaster = ({children}) => {
  const [queue, setQueue] = useState([]);

  const append = (toast) => setQueue([
    ...queue,
    {
      timeout: 3000,
      timestamp: new Date().getTime(),
      ...toast
    }
  ]);

  const imDone = (timestamp) => {
    setQueue(queue.filter((t) => t.timestamp !== timestamp));
  };

  return (
    <ToasterCTX.Provider value={{queue, append, imDone}}>
      {children}
      <ToastRenderer />
    </ToasterCTX.Provider>
  );
};

export default Toaster;
