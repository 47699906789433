import React from 'react'
import { createUseStyles } from 'react-jss'

import { useAppContext } from './hooks'

import Login from './Login'


const useStyles = createUseStyles(theme => ({
  topAppWindow: {
    display: "flex",
    flexDirection: 'column',
    flexWrap: "no-wrap",
    width: "100vw",
    height: "100vh",
  },
  topAppBackground: {
    position: "absolute",
    height: "100vh",
    width:"100vw",
    fill: theme.colors.primary.color,
    zIndex: -1,
    '& *': {
      maxHeight: "100%",
      maxWidth: "100%",
    }
  },
  brand: {
    opacity: .25,
    fill: theme.colors.primary.color,
  },
  topAppHead: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    fontSize: theme.size.lg,
    padding: theme.space.md,
    backgroundColor: theme.colors.primary.color,
    color: theme.colors.primary.offset,
  },
  topAppHeadBrand: {
    fontSize: theme.size.xl,
  },
  topAppHeadLeft: {
    marginRight: theme.space.md,
    marginLeft: theme.space.md,
    flexGrow: 1,
    '& > *': {
      boxSizing: "border-box",
      paddingLeft: theme.space.lg,
    }
  },
  topAppHeadRight: {
  },
  topAppBody: {
    flexGrow: 1,
    overflowX: "hidden",
    overflowY: "auto",
  },
  topAppFoot: {
    textAlign: "center",
    fontSize: theme.size.md,
    fontStyle: "oblique",
    opacity: .5,
  },
}))

const Fallback = (props) => {

  const { Brand } = props

  const classes = useStyles()

  const appContext = useAppContext()

  return (
    <div className={classes.topAppWindow}>

      <div className={classes.topAppBackground}>
        {Brand && (
          <Brand className={classes.brand} />
        )}
      </div>


      <div className={classes.topAppBody}>
        {appContext.canConnectToServer === false && (
          <div>Cannot connect to the server...</div>
        )}
        {appContext.requireLogin === true && (
          <Login />
        )}
      </div>

      <div className={classes.topAppFoot}>
        © {new Date().getFullYear()} Holmes Hobbies LLC / Jonathan Anderson
      </div>

    </div>
  )
}

export default Fallback
