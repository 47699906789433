import React from 'react'

import NavLink from './NavLink'


const AppNav = (props) => {
  const {
    links=[],
  } = props

  return (
    <React.Fragment>
      {links.map(({label, path}, id) => {
        return (
          <NavLink
            key={id}
            variant="link"
            to={path}
          >
            {label}
          </NavLink>
        )
      })}
    </React.Fragment>
  )
}

export default AppNav
