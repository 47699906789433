import React from 'react'

import TabLink from './TabLink'

import ContentBlock from './ContentBlock'
import ContentBlockTitle from './ContentBlockTitle'
import ContentBlockHead from './ContentBlockHead'
import Flex from './Flex'


const TabHead = (props) => {
  const {
    card=true,
    label,
    activeTab,
    tabs=[],
    onSelectTab,
  } = props

  return (
    <ContentBlock card={card}>
      <ContentBlockHead>
        {label && (
          <ContentBlockTitle flexGrow="0">
            {label}
          </ContentBlockTitle>
        )}
        <Flex container flexGrow="1">
          {tabs.map(t => {
            return (
              <TabLink
                key={t.id}
                active={t.id === activeTab.id}
                onClick={t.id === activeTab.id ? () => null : () => onSelectTab(t)}
                {...t}
              />)
          })}
        </Flex>
      </ContentBlockHead>
    </ContentBlock>
  )
}

export default TabHead
