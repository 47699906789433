import { Route as ReactRouterDomRoute } from 'react-router-dom'

import useWitchy from './useWitchy'


const Route = (props) => {

  let {
    route,
    ...routeProps
  } = props

  let ctx = useWitchy()

  const { path, ...configProps } = ctx.routes[route] || {}

  if ( !path ) {

    console.warn(`Witchy: route '${route}' could not be resolved`)
    return null

  }

  return (

    <ReactRouterDomRoute
      path={path}
      {...configProps}
      {...routeProps}
    />

  )

}

export default Route
