import React from 'react'
import clsx from 'clsx'
import { createUseStyles } from 'react-jss'


const useStyles = createUseStyles(theme => ({
  root: props => ({
    display: (props.container) ? 'flex' : undefined,
    flexGrow: (props.container) ? 1: undefined,
    flexWrap: (props.container) ? 'wrap' : undefined,
    flexDirection: (props.container) ? 'row' : undefined,
    boxSizing: 'border-box',
    ...props,
  }),
  spacingXS: {
    paddingTop: theme.space.xs,
    paddingBottom: theme.space.xs,
    paddingLeft: theme.space.xs,
    paddingRight: theme.space.xs,
  },
  spacingSM: {
    paddingTop: theme.space.sm,
    paddingBottom: theme.space.sm,
    paddingLeft: theme.space.sm,
    paddingRight: theme.space.sm,
  },
  spacingMD: {
    paddingTop: theme.space.md,
    paddingBottom: theme.space.md,
    paddingLeft: theme.space.md,
    paddingRight: theme.space.md,
  },
  spacingLG: {
    paddingTop: theme.space.lg,
    paddingBottom: theme.space.lg,
    paddingLeft: theme.space.lg,
    paddingRight: theme.space.lg,
  },
  spacingXL: {
    paddingTop: theme.space.xl,
    paddingBottom: theme.space.xl,
    paddingLeft: theme.space.xl,
    paddingRight: theme.space.xl,
  },
}))

const Flex = React.forwardRef(({
  children,
  xs, sm, md, lg, xl,
  show, hide, className,
  spacing,
  ...styleProps
}, ref) => {

  const styleClasses = useStyles(styleProps)

  const widthClasses = Object.entries({xs, sm, md, lg, xl}).reduce((widths, [name, size]) => {
    if (size) {
      widths.push(`flex-${name}-up-${size}`)
    }
    return widths
  }, [])

  const combinedClasses = clsx(
    styleClasses.root,
    widthClasses,
    (styleProps.container) ? 'flexContainer' : 'flexItem',
    (show === false) ? 'hide' : ((show && show !== true) && `hide-${show}-down`),
    (hide === true) ? 'hide' : ((hide && hide !== false) && `hide-${hide}-up`),
    className,
    spacing && styleClasses['spacing'+spacing.toUpperCase()]
  )

  return (
    <div ref={ref} className={combinedClasses}>
      {children}
    </div>
  )
})

export default Flex
