import React from 'react'


const Checkbox = (props, ref) => {
  const {
    children,
    ...inputProps
  } = props


  return (
    <input
      type="checkbox"
      ref={ref}
      {...inputProps}
    />
  )
}

export default React.forwardRef(Checkbox)
