import React from 'react'

import { Toaster } from 'hoodoo/components/Toaster'
import HelpBrowser from './components/HelpBrowser'


const GlobalComponents = () => {
  return (
    <React.Fragment>
      <Toaster />
      <HelpBrowser />
    </React.Fragment>
  )
}

export default GlobalComponents
