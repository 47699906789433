import React from 'react'
import ReactDOM from 'react-dom'

import Voodoo from 'voodoo/Voodoo'
import * as serviceWorker from './serviceWorker'


const renderApp = () => ReactDOM.render(
    <Voodoo />,
  document.getElementById('root')
)

if (process.env.NODE_ENV !== 'production' && module.hot) {
  module.hot.accept('voodoo/Voodoo', renderApp)
}

renderApp()

serviceWorker.unregister()
