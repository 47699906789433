import React from 'react'


const config = {
  apps: {
    fulfillment: {
      AppComponent: React.lazy(() => import('./App.js')),
      basePath: "/fulfillment",
      navRoute: "fulfillment.picking",
      navigation: true,
      navigationSequence: 300,
      label: "Fulfillment",
      routes: {
        dashboard: {
          path: "",
          exact: true,
        },
        picking: {
          path: "/picking",
          exact: true,
        },
        replenishment: {
          path: "/replenishment",
          exact: true,
        },
        packing: {
          path: "/packing",
          exact: true,
        },
        shipments: {
          path: "/shipments",
          virtual: true,
          routes: {
            list: { path:"", exact: true },
            read: { path:"/:shipmentId", exact: true },
          },
        },
      },
    },
  },
}

export default config
