import React from 'react'
import color from 'color'
import { createUseStyles } from 'react-jss'
import { breakUpMediaQueries } from 'ui/layout/GlobalStyles'


const useToastStyles = createUseStyles(theme => ({
  toastWrapper: {
    [breakUpMediaQueries.xs]: { width: "100vw" },
    [breakUpMediaQueries.md]: { width: "60vw" },
    [breakUpMediaQueries.xl]: { width: "40vw" },
  },
  toast: ({toastColor="default", ...props}) => ({
    margin: "10px",
    display: "flex",
    flexDirection: "row",
    color: theme.colors[toastColor].offset,
    backgroundColor: theme.colors[toastColor].color,
    borderRadius: "4px",
    boxShadow: `1px 1px 6px -1px ${color(theme.colors.primary.color).alpha(.3).hsl()}`,
    //zIndex: 100,
  }),
  toastContent: {
    flexGrow: 1,
    padding: theme.space.md,
    //zIndex: 100,
  },
  toastActions: {
    //flexGrow: 0,
    padding: theme.space.md,
    //zIndex: 100,
  },
}))

const useToasterStyles = createUseStyles(theme => ({
  toasterWrapper: {
    backgroundColor: "transparent",
    position: "fixed",
    bottom: "0px",
    right: "0px",
    zIndex: 10,
  }
}))

export const ToastContext = React.createContext({})

export const Toast = (props) => {

  const {
    children,
    color:toastColor="default",
    timeout=3000,
    onTimeout=() => null,
    actions=null,
  } = props

  const classes = useToastStyles({toastColor})

  React.useEffect(() => {
    if (timeout > 0) {
      const timer = setTimeout(onTimeout, timeout)
      return () => clearTimeout(timer)
    }
  }, []) // eslint-disable-line

  return (
    <div className={classes.toastWrapper}>
      <div className={classes.toast}>
        <div className={classes.toastContent}>
          {children}
        </div>
        <div className={classes.toastActions}>
          {actions}
        </div>
      </div>
    </div>
  )
}

export const Toaster = (props) => {
  const classes = useToasterStyles()

  const {queue} = React.useContext(ToastContext)

  return (
    <div className={classes.toasterWrapper}>
      <div className={classes.toastContent}>
        {Object.entries(queue).map(([key, {color, message, timeout, onTimeout, ...extraProps}]) => {
          return (
            <Toast
              key={key}
              color={color}
              timeout={timeout}
              onTimeout={onTimeout}
              {...extraProps}
            >
              {message}
            </Toast>
          )
        })}
      </div>
    </div>
  )
}

export const ToastProvider = (props) => {
  const [queue, setQueue] = React.useState({})

  const append = (toast) => {

    const key = new Date().getTime()

    setQueue(queue => ({ ...queue,
      [key]: { timeout: 3000, onTimeout: imDone(key), ...toast }
    }))
  }

  const imDone = (key) => () => {
    setQueue(queue => {
      const newQueue = {...queue}
      delete(newQueue[key])
      return newQueue
    })
  }

  const toast = {
    primary:    (message, options = {}) => append({color: "primary",    message, ...options}),
    secondary:  (message, options = {}) => append({color: "secondary",  message, ...options}),
    success:    (message, options = {}) => append({color: "success",    message, ...options}),
    warning:    (message, options = {}) => append({color: "warning",    message, ...options}),
    danger:     (message, options = {}) => append({color: "danger",     message, ...options}),
    default:    (message, options = {}) => append({color: "default",    message, ...options}),
  }


  React.useEffect(() => {
    window.toast = toast
    return () => delete(window.toast)
  }, []) // eslint-disable-line

  return (
    <ToastContext.Provider value={{queue}}>
      {props.children}
    </ToastContext.Provider>
  )
}
