import React from 'react'

import AppContext from './AppContext'

import bartholomew from 'bartholomew'
import { bartholomewCompat as legbe } from 'apis/legbe'

import { checkServer } from 'chwal'

import { voodooLog } from '../util'

const authenticator = (() => {

  if (process.env.REACT_APP_FEATURE_LEGBE_ENABLE === "1") {
    return legbe
  }

  return bartholomew

})()

export const tokenStorageKey = 'voodoo-jwt'


const getInitiAppState = intital => {
  let token = localStorage.getItem(tokenStorageKey)
  return {
    serverVersion: "",
    shouldCheckServer: false,
    isCheckingServer: false,
    canConnectToServer: null,
    hasApiToken: !!token,
    shouldCheckApiToken: false,
    isCheckingApiToken: false,
    appIsInitialized: false,
    isAppLoaded: false,
    isApiAuthenticated: false,
    requireLogin: false,
    appCommit: process.env.REACT_APP_COMMIT,
    userDisplayName: null,
    userRoles: [],
    userUsername: null,
    userEmail: null,
    isVersionStale: false,
  }
}

const appContextReducer = (state, action) => {

  let { type, payload } = action

  switch(type) {
    case "logout": 
      localStorage.removeItem(tokenStorageKey)
      return getInitiAppState()

    case "check-server":
      voodooLog("Server check requested")
      return {...state, shouldCheckServer: true}

    case "check-server-start":
      voodooLog("Server check starting")
      return {...state, isCheckingServer: true}

    case "check-server-finish":
      voodooLog("Server check complete:", (payload.success) ? "ok" : "fail")
      return {
        ...state,
        shouldCheckServer: false,
        isCheckingServer: false,
        canConnectToServer: payload.success,
        serverVersion: payload.version !== state.serverVersion ? payload.version : state.version,
      }

    case "start-login":
      voodooLog("Starting login")
      return {...state, requireLogin: true}

    case "receive-token":
      voodooLog("API token received")
      localStorage.setItem(tokenStorageKey, payload.token)
      return {...state, requireLogin: false, hasApiToken: true}

    case "check-token":
      if (state.hasApiToken) {
        voodooLog("API token verify requested")
        return {...state, shouldCheckApiToken: true }
      } else {
        voodooLog("No API token to verifying, bailing out...")
        return state
      }

    case "check-token-start":
      voodooLog("API token verify starting")
      return {...state, isCheckingApiToken: true}

    case "check-token-finish":
      if (payload.authenticated) {
        voodooLog(
          "API token verify complete: OK, user: ", payload.username,
          " expires ", payload.tokenExpires || "NOT SET"
        )
        return {
          ...state,
          shouldCheckApiToken: false,
          isCheckingApiToken: false,
          isApiAuthenticated: true,
          userUsername: payload.username,
          userEmail: payload.email,
          userDisplayName: payload.displayName,
          userRoles: payload.rolesByReference || [],
          userIpAddress: payload.ipAddress,
          userUserAgent: payload.userAgent,
        }
      } else {
        voodooLog("API token verify complete: FAIL, dropping token")
        localStorage.removeItem(tokenStorageKey)
        return {
          ...state,
          shouldCheckApiToken: false,
          isCheckingApiToken: false,
          isApiAuthenticated: false,
          hasApiToken: false,
        }
      }

    case "app-init-finish":
      voodooLog("Application initialized")
      return {...state, isAppLoaded: true}

    case "setVersionStale": 
      return {...state, isVersionStale: true}

    default:
      voodooLog("Invalid AppContext action: "+type)
      return state

  }

}

/**
 * load and initialize the app, server comms
 *
 * TODO: AppContextPovider
 * [ ] Auto-retry if canConnectToServer becomes false
 */
const AppContextProvider = ({children}) => {

  const version = document.querySelector('meta[name="client-version"]').content.replace('v', '')
  const build = document.querySelector('meta[name="client-build"]').content

  const [appContext, dispatch] = React.useReducer(appContextReducer, {}, getInitiAppState)


  const loadApplication = () => {
    voodooLog("loading app")
  }

  const setApiToken = token => {
    dispatch({type:"receive-token", payload: {token}})
  }

  const logout = () => {
    dispatch({type:"logout"})
  }

  React.useEffect(() => {

    if ( appContext.isAppLoaded === false ) {

      // Start the server check if we haven't already
      if (appContext.canConnectToServer === null && !appContext.shouldCheckServer) {
        // have not yet tested the server, check now
        voodooLog("Start server connection test")
        dispatch({type: "check-server"})
      }

      // Do the server check
      if (appContext.shouldCheckServer === true && appContext.isCheckingServer === false) {
        voodooLog("Checking server connection")
        dispatch({type: "check-server-start"})

        ;(async () => {
          let response = await checkServer()
          voodooLog("Server connect: "+((response.success) ? "OK" : "FAIL"))
          dispatch({type: "check-server-finish", payload: response})
        })()
      }

      // We can communicate with the server, continue to auth checks
      if (appContext.canConnectToServer === true) {

        // if no token, assume we're completely unauthed, require login
        if ( appContext.requireLogin === false && appContext.hasApiToken === false ) {
          voodooLog("Require login")
          dispatch({type: "start-login"})
        }

        if (appContext.hasApiToken === true) {

          // bail out here, we don't have any other initialization to do yet
          if ( appContext.isApiAuthenticated === true ) {
            dispatch({type: "app-init-finish"})
            return null
          } // END INITIALIZE APP

          // we have a token, let's check it
          if (
            appContext.isApiAuthenticated === false
            && appContext.shouldCheckApiToken === false
          ) {
            dispatch({type: "check-token"})
          }

          // do the token check
          if (
            appContext.shouldCheckApiToken === true
            && appContext.isCheckingApiToken === false
          ) {

            dispatch({type: "check-token-start"})

            ;(async () => {
              let response = await authenticator.checkAuthentication()
              dispatch({type: "check-token-finish", payload: response})
            })()

          }

        } // END Has API Token Block

      } // END Good Connection Block

    }

  })

  const appContextValue = {
    ...appContext,
    version, build,
    loadApplication,
    setApiToken,
    logout,
  }

  return (
    <AppContext.Provider value={appContextValue}>
      {children}
    </AppContext.Provider>
  )

}

export default AppContextProvider
