import React from 'react'
import { useForm } from 'react-hook-form'

import { useAuthState } from 'authentication' 

import Input from 'hoodoo/components/Input'
import InputLabel from 'hoodoo/components/InputLabel'
import InputControl from 'hoodoo/components/InputControl'
import InputErrors from 'hoodoo/components/InputErrors'


const LoginForm = ({onReceiveToken}) => {
  const auth = useAuthState()

  const {register, handleSubmit} = useForm({
    defaultValues: {username: "", password: ""}
  })

  const [loginError, setLoginError] = React.useState(null)

  const doLogin = async ({username, password}) => {

    let resp = await auth.login(username, password)
    let { token, error } = resp
    if (error) {
      setLoginError(error.message)
    } else {
      onReceiveToken(token)
    }
  }

  return (
    <form onSubmit={handleSubmit(doLogin)}>
        {loginError && (
      <InputControl>

        <InputErrors errors={[loginError]} />
      </InputControl>
        )}
      <InputControl variant="outlined" hasError={!!loginError}>
        <InputLabel>Username</InputLabel>
        <Input
          ref={register}
          autoFocus
          name="username"
          type="text"
        />
      </InputControl>
      <InputControl variant="outlined" hasError={!!loginError}>
        <InputLabel>Password</InputLabel>
        <Input
          ref={register}
          name="password"
          type="password"
        />
      </InputControl>
      <InputControl variant="outlined">
        <Input
          type="submit"
          value="Login"
        />
      </InputControl>
    </form>
  )
}

export default LoginForm
