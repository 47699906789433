import React from 'react'
import { isEmpty, intersection } from 'lodash'
import { useLocation } from 'react-router-dom'

import BlockContext from '../BlockContext'


export const useSearchParams = (defaults={}) => {
  return Array.from(new URLSearchParams(useLocation().search).entries())
    .reduce((acc, [key, value]) => {
      acc[key] = value
      return acc
    }, defaults)

}

/**
 * convenience hook for getting the block context deeper down
 */
export const useBlockContext = () => {
  return React.useContext(BlockContext)
}

/**
 * async context fetch reducer login
 */
export const initialAsyncContextState = {
  context: {},
  hasContext: false,
  gettingContext: false,
  shouldGetContext: false,
}

/**
 * async context fetch hook
 */
export const asyncContextReducer = (state, {action, ...payload}) => {
  switch (action) {

    case 'getContext':
      return {...state,
        shouldGetContext: true,
        gettingContext: true,
      }

    case 'receiveContext':
      return {...state,
        shouldGetContext: false,
        gettingContext: false,
        hasContext: !isEmpty(payload.context),
        context: payload.context,
      }

    default:
      console.log('asynContextReducer: unconfigured action, possible coding error.')
      return state
  }
}

/**
 * the reducer as a hook for convenience
 */
export const useAsyncContextReducer = (initial) => {
  return React.useReducer(asyncContextReducer, {
    ...initialAsyncContextState,
    ...initial
  })
}

/**
 * FilterList reducer
 */
const filterListReducer = (state, {action, ...payload}) => {
  switch(action) {
    case 'getData':
      return {...state, shouldFetch: true}

    case 'receiveData':
      const firstRecord = (state.page - 1 ) * state.pageSize + 1
      const {
        meta= {},
      } = payload

      const {
        count=0,
      } = meta

      return {...state,
        shouldFetch: false,
        data: payload.data,
        dataIds: payload.dataIds,
        selectedAll: intersection(payload.dataIds, state.selected).length === payload.dataIds.length,
        count ,
        firstRecord,
        lastRecord: firstRecord - 1 + payload.data.length,
      }

    case 'updateFilter':
      return {...state,
        shouldFetch: true,
        page: 1, // if the filter reduces total count, we don't want an empty page
        filterValues: {...state.filterValues, [payload.name]: payload.value},
      }

    case 'nextPage':
      return {...state,
        shouldFetch: true,
        page: state.page+1,
      }

    case 'prevPage':
      if (state.page > 1) {
        return {...state,
          shouldFetch: true,
          page: state.page-1,
        }
      } else {
        return state
      }

    case 'goToPage':
      return {...state,
        shouldFetch: true,
        page: payload.page,
      }

    case 'setPageSize':
      return {...state,
        shouldFetch: true,
        pageSize: payload.pageSize,
        page: 1,
      }

    case 'setSelection':
      return {
        ...state,
        selected: payload.selected,
        selectedAll: intersection(payload.selected, state.dataIds).length === state.dataIds.length
      }

    default:
      console.log('misconfigured list reducer, action: '+action)
      return state
  }
}

/**
 * reducer hook for above
 */
export const useFilterList = (props) => {
  const {
    getData,
    filters={},
    initialData=[],
    filterValues:defaultFilterValues={},
    pageSize:defaultPageSize=50,
    page:defaultPage=1,
    enableMultiSelect=false,
    getMultiSelectValue = v => v.id,
    multiSelectActions={},
  } = props

  const mounted = React.useRef(false)

  const [ state, dispatch ] = React.useReducer(filterListReducer, {
    data: initialData,
    hasData: isEmpty(initialData),
    shouldFetch: true,
    filterValues: defaultFilterValues,
    page: defaultPage,
    pageSize: defaultPageSize,
    count: null,
    firstRecord: null,
    lastRecord: null,
    selected: [],
    selectedAll: false,
    multiSelect: enableMultiSelect,
    dataIds: (enableMultiSelect) ? initialData.map(i => getMultiSelectValue(i)) : []
  })

  const {
    filterValues,
    page,
    pageSize,
    count,
    firstRecord,
    lastRecord,
    shouldFetch,
    multiSelect,
    selected,
    selectedAll,
  } = state

  const handleGetData = async () => {

    let resp = await getData({
      filters: {
        ...filterValues,
        limit: pageSize,
        offset: pageSize * (page - 1),
      }
    })

    if ( mounted.current ) {
      dispatch({
        action: 'receiveData',
        dataIds: resp.data ? resp.data.map(getMultiSelectValue) : [],
        ...resp,
      })
    }
  }

  React.useEffect(() => {
    mounted.current = true

    if (mounted.current && shouldFetch) {
      handleGetData()
    }

    // prevent updates on unmounted
    return () => mounted.current = false
  })

  const _lastPage = (count !== null && pageSize > 0) ? Math.ceil(count / pageSize) : null

  const refetch = () => dispatch({action:'getData'})

  return {
    data: state.data,
    updateFilter: (name, value) => dispatch({action: 'updateFilter', name, value}),
    refetch,
    filters,
    filterValues,
    multiSelect,
    multiSelectActions,
    selected,
    selectedAll,
    select: {
      all: () => {
        dispatch({
          action: "setSelection",
          selected: state.data.map(i => getMultiSelectValue(i)),
        })
      },
      none: () => {
        dispatch({
          action: "setSelection",
          selected: [],
        })
      },
      toggle: dataItem => {
        return () => {
          let newSelected = [...selected]
          let indexValue = getMultiSelectValue(dataItem)
          let index = newSelected.indexOf(indexValue)

          if (index >= 0) {
            newSelected.splice(index, 1)
          } else {
            newSelected.push(indexValue)
          }

          dispatch({
            action: "setSelection",
            selected: [...newSelected]
          })
        }
      },
      isSelected: dataItem => {
        return selected.indexOf(getMultiSelectValue(dataItem)) >= 0
      },
    },
    pagination: {
      page,
      pageSize,
      count,
      firstRecord,
      lastRecord,
      nextPage: () => dispatch({action: "nextPage"}),
      previousPage: () => dispatch({action: "prevPage"}),
      firstPage: () => dispatch({action: "goToPage", page: 1}),
      lastPage: () => dispatch({action: "goToPage", page: _lastPage}),
      setPageSize: (pageSize) => dispatch({action: 'setPageSize', pageSize}),
      refetch,
    }
  }
}

export const useClickaway = (ref, handler) => {
  const clickAwayHandler = e => {
    if (ref.current && !ref.current.contains(e.target)) {
      handler()
    }
  }

  React.useEffect(() => {
    document.addEventListener('click', clickAwayHandler)
    return () => {
      document.removeEventListener('click', clickAwayHandler)
    }
  })
}

export const useEscaway = (handler) => {
  const escAwayHandler = e => {
    let code = (e.keyCode ? e.keyCode : e.which);
    if ( code === 27 ) {
      handler()
    }
  }

  React.useEffect(() => {
    document.addEventListener('keydown', escAwayHandler)
    return () => {
      document.removeEventListener('keydown', escAwayHandler)
    }
  })
}
