import React from 'react'

const hoodooConfig = {
  apps: {
    accounting: {
      AppComponent: React.lazy(() => import('./App.js')),
      basePath: "/accounting",
      label: "Accounting",
      navRoute: 'accounting.invoices.list',
      navigation: true,
      navigationSequence: 900,
      routes: {
        home: {
          path: "",
          exact: true,
        },
        invoices: {
          path: "/invoices",
          exact: false,
          virtual: false, // allows the route to be targeted for subroutes
          routes: {
            list: { path: "", exact: true },
            create: { path: "/new", exact: true },
            update: { path: "/:invoiceId/edit", exact: true },
            read: { path: "/:invoiceId", exact: true },
          },
        },
        accounts: {
          path: "/accounts",
          virtual: true,
          routes: {
            list: { path: "", exact: true },
            read: { path: "/:contactId", exact: true },
          },
        },
        payments: {
          path: "/payments",
          exact: false,
          virtual: false,
          routes: {
            list: { path: "", exact: true },
            create: { path: "/new", exact: true },
            update: { path: "/:paymentId/edit", exact: true },
            read: { path: "/:paymentId", exact: true },
          },
        },
      },
    },
  },
}

export default hoodooConfig
