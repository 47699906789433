import React from 'react'
import clsx from 'clsx'


const TableHeading = (props) => {

  const {
    children,
    align,
    shrink = false,
    wrap,
    danger,
    success,
    warning,
    ...elementProps
  } = props

  return (

    <th
      className={clsx(
        shrink && 'shrinkCell',
        wrap && 'wrap',
        align && align,
      )}
      {...elementProps}
    >
      {children}
    </th>

  )

}

export default TableHeading
