const [
  MAJOR_VERSION,
  MINOR_VERSION,
  PATCH_VERSION
] = process.env.REACT_APP_VERSION.split('.')

const config = {
  VERSION:  process.env.REACT_APP_VERSION,
  MAJOR_VERSION,
  MINOR_VERSION,
  PATCH_VERSION,
  VERSION_CHECK_INTERVAL: 1000 * 60 * 30, // 30 minutes
  AUTH_CHECK_INTERVAL: 1000 * 60 * 1, // 1 minutes
  AUTH_CHECK_WARN: 1000 * 60 * 2, // 2 minutes
  SERVER_CHECK_INTERVAL: 1000 * 30, // 30 seconds
  API_PROTOCOL: process.env.REACT_APP_API_PROTOCOL,
  API_HOST: process.env.REACT_APP_API_HOST,
  API_BASEPATH: process.env.REACT_APP_API_BASEPATH,
  API_PORT: process.env.REACT_APP_API_PORT,
  COMMIT: process.env.REACT_APP_COMMIT,
  FEATURE_FLAG: {
    USER_MANAGER: process.env.REACT_APP_FEATURE_USER_MANAGER === '1',
    PRODUCT_MANAGER: process.env.REACT_APP_FEATURE_PRODUCT_MANAGER === '1',
    INVENTORY_MANAGER: process.env.REACT_APP_FEATURE_INVENTORY_MANAGER === '1',
    PAYROLL: process.env.REACT_APP_FEATURE_PAYROLL === '1',
    MANUFACTURING: process.env.REACT_APP_FEATURE_MFG === '1',
    SALES: process.env.REACT_APP_FEATURE_SALES === '1',
    PURCHASES: process.env.REACT_APP_FEATURE_PURCHASES === '1',
    INVOICES: process.env.REACT_APP_FEATURE_INVOICES === '1',
    QUICK_BUILD: process.env.REACT_APP_FEATURE_QUICK_BUILD === '1',
  },
}

export default config
