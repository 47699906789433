import React from 'react'
import { useHistory } from 'react-router-dom'
import { createUseStyles } from 'react-jss'

import { useAuthState } from 'authentication' 

import { useAppContext, useSearchParams } from './hooks'
import LoginForm from './components/LoginForm'
import LoginResetRequestForm from './components/LoginResetRequestForm'
import LoginPasswordResetForm from './components/LoginPasswordResetForm'


const useStyles = createUseStyles(theme => ({
  loginWrapper: {
    marginTop: "30vw",
    marginLeft: "auto",
    marginRight: "auto",
    width: "40vw",
    '& p': {
      textAlign: "center",
    }
  }
}))


const Login = () => {
  const classes = useStyles()
  const auth = useAuthState()
  const appContext = useAppContext()
  const { token } = useSearchParams()

  const history = useHistory()

  const [mode, setMode] = React.useState((token) ? 'reset' : 'login')

  const onReceiveToken = token => {
    appContext.setApiToken(token)
  }

  const handleForgotPasswordClick = e => {
    e.preventDefault()
    e.stopPropagation()
    setMode('request')
  }

  const handleResetRequest = ({username}) => {
    auth.passwordResetRequest(username)
    setMode('request-sent')
    setTimeout(() => setMode('login'), 3000)
  }

  const handleChangePassword = async ({password, passwordConfirm}) => {
    console.log('atteempting change')
    let response = await auth.passwordResetComplete(token, password, passwordConfirm)
    console.log(response)
    if (response.error) {
      console.log('error found', response.error)
      window.toast.danger(response.error.message)
      //setMode("password-change-failed")
      //setTimeout(() => setMode('login'), 3000)
    } else if (response?.status === "ok") {
      console.log('all good')
      window.toast.success("Password changed")
      setMode('password-changed')
      history.replace('/')
      setTimeout(() => setMode('login'), 3000)
    }
  }

  return (
    <div className={classes.loginWrapper}>
      {mode === 'login' && (
        <React.Fragment>
          <LoginForm onReceiveToken={onReceiveToken} />
          <p>Forgot your password?
            It's {new Date().getFullYear()}. Get a password manager, then
            click <a onClick={handleForgotPasswordClick} href="/">here</a>.</p>
        </React.Fragment>
      )}
      {mode === 'request' && (
        <React.Fragment>
          <LoginResetRequestForm onSubmit={handleResetRequest} />
          <p>Enter your username. A reset link will be sent to the associated
          email address.</p>
        </React.Fragment>
      )}
      {mode === 'request-sent' && (
        <p>Your request is being processed</p>
      )}
      {mode === 'reset' && (
        <React.Fragment>
        <LoginPasswordResetForm onSubmit={handleChangePassword} />
        <p>You may now change your password. 8 or more characters. Try not to forget it this time. You will still have to login after this after all...</p>
        </React.Fragment>
      )}
      {mode === 'password-change-failed' && (
        <p>No good. Didn't take. You probably waited too long, or already changed your password.</p>
      )}
      {mode === 'password-changed' && (
        <p>Your password has been changed.</p>
      )}

    </div>
  )
}

export default Login
