import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Button from './Button'


const maybeFunc = (suspect, ...args) => {
  if (typeof suspect === 'function') {
    return suspect(...args)
  } else {
    return suspect
  }
}

const ActionButton = (props) => {
  const {
    label='',
    visible=true,
    icon,
    ...buttonProps
  } = props

  const isDisabled = maybeFunc(buttonProps.disabled)
  const isVisible = maybeFunc(visible)

  if (!isVisible) return null

  return (
    <Button
      disabled={isDisabled}
      {...buttonProps}
    >
      {icon && (
        <span style={{marginRight: label ? "5px" : null}}>
          <FontAwesomeIcon icon={icon} />
        </span>
      )}
      {label && label}
    </Button>
  )
}

export default ActionButton
