const theme = {
  fontFamily: "serif",
  backgroundColor: "white",
  colors: {
    default: { color: 'black', backgroundColor: "white", offset: 'white'},
    primary: { color: 'purple', backgroundColor: "white", offset: 'white'},
      secondary: { color: 'lightgray', offset: 'black'},
    
  },
}

export default theme
