import React from 'react'
import { createUseStyles } from 'react-jss'


const useStyles = createUseStyles(theme => ({
  contentBlockFoot: props => ({
    flexGrow: 0,
    display: "flex",
    flexDirection: "row",
    ...props,
  }),
}))

const ContentBlockFoot = ({children, ...styleProps}) => {
  const classes = useStyles(styleProps)

  return (
    <div className={classes.contentBlockFoot}>
      {children}
    </div>
  )
}

export default ContentBlockFoot
