import React from 'react'

const messages = [
  'Doing some voodoo',
  'Dark magics underway',
  'Papa Legbe is having a look',
  'Consulting arcane sources',
]

const getLoadingText = () => {

  return messages[Math.floor(Math.random()*messages.length)];

}

const DefaultFallback = () => {

  return (

    <div>{getLoadingText()}...</div>

  )

}

export default DefaultFallback
