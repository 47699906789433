import { Switch, Route } from "react-router-dom"

import { useBlockContext } from '../hooks'
import AppLoader from '../AppLoader'
import AppErrorBoundary from '../AppErrorBoundary'



/**
 * render switch router for the base (root) applications, setting active app
 * context and lazy loading
 */
const BaseAppRouterBlock = (props) => {

  const {apps} = useBlockContext()


  return (
    <Switch>
      {Object.entries(apps).map(([appName, config]) => {
        const appConfig = apps[appName]
        if (!appConfig) return null

        return (
          <Route
            key={appName}
            path={appConfig.basePath}
            exact={appConfig.basePathExact === undefined ? false : appConfig.basePathExact}
          >
            <AppErrorBoundary>
              <AppLoader appName={appName} appConfig={appConfig} />
            </AppErrorBoundary>
          </Route>
        )
      })}
    </Switch>
  )
}

export default BaseAppRouterBlock
