import { createUseStyles } from 'react-jss'

// TODO: remove ui/layout/GlobalStyles if unused

const breakPoints = {
  xs: 400,
  sm: 600,
  md: 800,
  lg: 1000,
  xl: 1200,
}

const breakUp = {
  xs: 0,
  sm: breakPoints.xs -1,
  md: breakPoints.sm -1,
  lg: breakPoints.md -1,
  xl: breakPoints.lg -1,
}

const basisValues = []
for (var i=1; i<=12; i++) {
  basisValues.push([i,`${100 / 12 * i}%`])
}

export const mediaStyles = {}
Object.entries(breakUp).forEach(([size, width]) => {
  basisValues.reverse().forEach(([id, value]) => {
    mediaStyles[`.flex-${size}-up-${id}`] = {
      [`@media screen and (min-width: ${width}px)`]: {
        flexBasis: value,
      }
    }
  })
})

export const breakUpMediaQueries = {}
Object.entries(breakUp).forEach(([size, width]) => {
  breakUpMediaQueries[`${size}`] = `@media screen and (min-width: ${width}px)`
})

Object.entries(breakPoints).forEach(([size, width]) => {
  mediaStyles[`.hide-${size}-down`] = {
    [`@media screen and (max-width: ${width}px)`]: {
      display: 'none !important',
    }
  }
  mediaStyles[`.hide-${size}-up`] = {
    [`@media screen and (min-width: ${width}px)`]: {
      display: 'none !important',
    }
  }
})

const baseStyles = {
  '@global': {
    ...mediaStyles,
    '.hide': {
      display: 'none !important',
    },
    /**
     * from https://www.w3schools.com/howto/howto_css_custom_scrollbar.asp (who uses asp?)
     * much of this seems to be ignored by the browser
     * ::-webkit-scrollbar the scrollbar.
     * ::-webkit-scrollbar-button the buttons on the scrollbar (arrows pointing upwards and downwards).
     * ::-webkit-scrollbar-thumb the draggable scrolling handle.
     * ::-webkit-scrollbar-track the track (progress bar) of the scrollbar.
     * ::-webkit-scrollbar-track-piece the track (progress bar) NOT covered by the handle.
     * ::-webkit-scrollbar-corner the bottom corner of the scrollbar, where both horizontal and vertical scrollbars meet.
     * ::-webkit-resizer the draggable resizing handle that appears at the bottom corner of some elements.
     */
    '::-webkit-scrollbar':{
      width: "5px",
      height: "5px",
    },
    '::-webkit-scrollbar-button':{
      display: "none",
    },
    '::-webkit-scrollbar-thumb':{
      backgroundColor: "purple",
      borderRadius: "3px",

    },
    //'::-webkit-scrollbar-track':{
      //color: "red",
    //},
    //'::-webkit-scrollbar-track-piece':{
      //color: "green",
    //},
  },
}

const useStyles = createUseStyles(baseStyles)

const GlobalStyles = ({children}) => {
  useStyles()
  return children
}

export default GlobalStyles
