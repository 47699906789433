import React from 'react'


const Textarea = (props, ref) => {
  const {
    children,
    ...inputProps
  } = props

  const handleAutoSize = e => {
    let el = e.target
    // extra 2px is a hack to account for the padding, I think....
    el.style.height = (el.scrollHeight+2)+"px"
  }

  return (
    <textarea
      onInput={handleAutoSize}
      onFocus={handleAutoSize}
      // TODO: Textarea: add func to shrink to initial on blur evt
      //onBlur={handleAutoSize}
      ref={ref}
      {...inputProps}
    />
  )
}

export default React.forwardRef(Textarea)
