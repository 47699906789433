import useWitchy from './useWitchy'


const withWitchy = WrappedComponent => {

  return (props) => {

    const {context, ...ctx} = useWitchy()

    return (

      <WrappedComponent {...props} context={context} {...ctx} />

    )

  }

}

export default withWitchy
