import React from 'react'
import color from 'color'
import clsx from 'clsx'
import { createUseStyles } from 'react-jss'


const useStyles = createUseStyles(theme => ({
  block: props => ({
    //margin: '5px 10px',
    marginTop: theme.space.sm,
    marginBottom: theme.space.sm,
    marginLeft: theme.space.md,
    marginRight: theme.space.md,
    paddingTop: theme.space.sm,
    paddingBottom: theme.space.sm,
    paddingLeft: theme.space.md,
    paddingRight: theme.space.md,
    ...props
  }),
  cardStyles: {
    boxShadow: `1px 1px 6px -1px ${color(theme.colors.primary.color).alpha(.3).hsl()}`,
    backgroundColor: theme.colors.primary.offset,
  },
  blockHead: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.space.sm,
  },
  blockHeadLeft: {
  },
  blockHeadCenter: {
    flexGrow: 1,
  },
  blockHeadRight: {
  },
  blockFoot: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.space.sm,
  },
  blockFootLeft: {
  },
  blockFootRight: {
  },
  blockCaption: {
    fontSize: theme.size.sm,
    fontStyle: "oblique",
  },
  blockLabel: {
    fontSize: theme.size.xl,
  },
}))

const Block = (props) => {
  const {
    label,
    HeadActions,
    FootActions,
    caption,
    card=false,
    children,
    ...styleProps
  } = props

  const classes = useStyles(styleProps)

  return (
    <div className={clsx(classes.block, card && classes.cardStyles)}>
      <div className={classes.blockHead}>
        <div className={classes.blockHeadLeft}>
        </div>
        <div className={classes.blockHeadCenter}>
          {label && (<div className={classes.blockLabel}>{label}</div>)}
          {caption && (<div className={classes.blockCaption}>{caption}</div>)}
        </div>
        <div className={classes.blockHeadRight}>
          {HeadActions && (<HeadActions />)}
        </div>
      </div>
      {children}
      <div className={classes.blockFoot}>
        <div className={classes.blockFootLeft}>
        </div>
        <div className={classes.blockFootRight}>
          {FootActions && (<FootActions />)}
        </div>
      </div>
    </div>
  )
}

export default Block
