import React from 'react'


const config = {
  apps: {
    manufacturing: {
      AppComponent: React.lazy(() => import('./App.js')),
      basePath: "/manufacturing",
      navRoute: "manufacturing.boms.list",
      navigation: true,
      navigationSequence: 300,
      label: "Manufacturing",
      routes: {
        dashboard: {
          path: "",
          exact: true,
        },
        boms: {
          path: "/boms",
          virtual: true,
          routes: {
            list:   { path: '', exact: true, },
            create: { path: '/create', exact: true, },
            read:   { path: '/:bomId', exact: true, },
            update:   { path: '/:bomId/edit', exact: true, },
          }
        },
        production: {
          path: "/production",
          exact: true,
        }
      },
    },
  },
}

export default config
