import React from 'react'


class BlockErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    //
  }

  render() {
    if (this.state.hasError) {
      return (
        <div>
          <h3>Something isn't quite right....</h3>
          <p>This component has succumbed to some dark magics...</p>
        </div>
      )
    }

    return this.props.children; 
  }
}

export default BlockErrorBoundary
