import React from 'react'
import { 
  Route as ReactRouterDomRoute,
  Switch as ReactRouterDomSwitch
} from 'react-router-dom'

import useWitchy from './useWitchy'


const Switch = (props) => {

  const { children } = props

  let ctx = useWitchy()

  return (

    <ReactRouterDomSwitch>

      {React.Children.map(children, c => {

        const { route, ...routeProps } = c.props
        const { path, ...configProps } = ctx.routes[route] || {}

        if ( !path ) {

          console.warn(`Witchy: route '${route}' could not be resolved`)
          return null

        }

        return (

          <ReactRouterDomRoute
            path={path}
            {...configProps}
            {...routeProps}
          />

        )

      })}

    </ReactRouterDomSwitch>

  )
}

export default Switch
